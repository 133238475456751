import { Box, Button, Grid, GridItem } from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";
import CategoryTableData from "./CategoryTableData";
import AddCategoryModal from "./AddCategoryModal";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCategoryDel, getCategory } from "../../redux/CategorySlice";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import Loading from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ToastContainer, toast } from "react-toastify";
import { refreshToken } from "../../redux/LoginSlice";

export default function CategoryList() {
  const {
    isOpen: isAddModal,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.category.getCategory);
  const deleteStatus = useSelector((state) => state.category.categorySubDelete);
  const deleteCategoryStatus = useSelector(
    (state) => state.category.categoryDelete
  );
  const loading = useSelector((state) => state.category.loading);
  const adding = useSelector((state) => state.category.adding);

  useEffect(() => {
    dispatch(getCategory());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  const clearDelete = () => {
    dispatch(clearCategoryDel());
  };

  useEffect(() => {
    if (deleteStatus?.status === 400) {
      toast.error(deleteStatus.data.message);
      confirmAlert({
        title:
          deleteStatus.data.data.length +
          " Products are linked with this subcategory",
        message:
          "Please remove product or change sub-category of product first.",
        buttons: [
          {
            label: "Ok",
            onClick: () => clearDelete(),
          },
        ],
      });
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (deleteCategoryStatus?.status === 400) {
      toast.error(deleteCategoryStatus.data.message);
      confirmAlert({
        title:
          deleteCategoryStatus.data.data.length +
          " Products are linked with this category",
        message: "Please remove product or change category of product first.",
        buttons: [
          {
            label: "Ok",
            onClick: () => clearDelete(),
          },
        ],
      });
    }
  }, [deleteCategoryStatus]);

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {loading ? <Loading /> : ""}
          <ToastContainer />
          <Grid
            templateColumns="repeat(8, 1fr)"
            gap={1}
            borderBottom={"1px solid lightgray"}
            p={2}
          >
            <GridItem colSpan={6}>
              <PageTitle title="Product Categories" />
            </GridItem>
            <GridItem colSpan={2}>
              <Button onClick={onAddOpen}>Add Category</Button>
            </GridItem>
          </Grid>

          <Box>
            <Grid
              templateColumns="repeat(8, 1fr)"
              fontSize={"1rem"}
              gap={1}
              borderBottom={"1px solid lightgray"}
              p={2}
            >
              <GridItem colSpan={1}>Icon</GridItem>
              <GridItem colSpan={5}>Name</GridItem>
              <GridItem colSpan={2}>Edit Category</GridItem>
            </Grid>
          </Box>

          {Array.isArray(categoryList)
            ? categoryList.map((item) => <CategoryTableData data={item} />)
            : ""}

          {/* Add Modal */}
          <AddCategoryModal
            isAddModal={isAddModal}
            onAddClose={onAddClose}
            onAddOpen={onAddOpen}
          />
        </>
      }
    />
  );
}
