import { Box, Grid, GridItem } from "@chakra-ui/react";
import OrderTableData from "./OrderTableData";
export default function OrderListTable({data}){
    return(
        <>

        <Box>
            {/* Table Heading */}
            <Grid fontSize={'1rem'} templateColumns='repeat(10, 1fr)' gap={1}  borderBottom={"1px solid lightgray"} p={2} mb={2} >
                <GridItem>
                    Order Id
                </GridItem>
                <GridItem colSpan={2}>
                    Customer Name
                </GridItem>
                <GridItem colSpan={2}>
                    Shipping Address
                </GridItem>
                <GridItem>
                    Price
                </GridItem>
                <GridItem colSpan={2}>
                    Date
                </GridItem>
                <GridItem>
                    Status
                </GridItem>
                <GridItem>
                    Action
                </GridItem>
            </Grid>
        </Box>
        {
            data.map((item)=>(
                <OrderTableData data={item}/>
            ))
        }
        
        </>
    );
}