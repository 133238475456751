import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getNotes = createAsyncThunk(
  "api/checkout-note/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/checkout-note/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const updateNotes = createAsyncThunk(
  "api/checkout-note/",
  async (values, thunkAPI) => {
    console.log("vv", values);
    const res = await axios.patch(
      `${baseUrl}/api/checkout-note/`,
      {
        checkout_note: values.checkout_notes,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    if (res.status === 200) {
      window.location.reload();
    }
    return res.data.data;
  }
);

export const notesSlice = createSlice({
  name: "notes",
  initialState: {
    loading: false,
    adding: false,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
  },
  extraReducers: {
    [getNotes.fulfilled]: (state, { payload }) => {
      state.notes = payload;
    },
  },
});

export const notesReducer = notesSlice.reducer;
// export const { loading, adding } = bannerSlice.actions;
