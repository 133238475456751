import React from "react";
import { Button } from "@chakra-ui/react";

export const CancelButton = ({ onClick }) => {
  return (
    <Button
      ml="2rem"
      mt="5rem"
      w="7rem"
      size="sm"
      shadow="base"
      _active={{
        transform: "scale(.97) !important",
      }}
      onClick={onClick}
    >
      Cancel
    </Button>
  );
};
