import { Box, Input, Text } from "@chakra-ui/react";
import React from "react";

export const InputField = ({ label, type, formik, inputId, formikValue }) => {
  return (
    <>
      <Box mt="1rem">
        {" "}
        <Text fontWeight={"400"} fontSize="md" mb="0.5rem">
          {label}
        </Text>
        <Input
          id={inputId}
          type={type}
          onChange={formik.handleChange}
          // value={formikValue}
        />
      </Box>
    </>
  );
};
