import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getCustomer = createAsyncThunk(
  "api/admin/user-list/",
  async (values, thunkAPI) => {
    let search = values.params === "" ? "None" : values.params;

    const res = await axios.get(
      `${baseUrl}/api/admin/user-list/?query=` +
        search +
        `&page_number=` +
        values.page_number +
        `&page_size=` +
        values.page_size +
        `&status=` +
        values.status+
        `&myUsers=${values.myUsers}`
        ,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    return res.data.data;
  }
);

export const addCustomer = createAsyncThunk(
  "api/admin/add-user/",
  async (values, { dispatch, rejectWithValue }) => {
    const formData = new FormData();

    formData.append("full_name", values.name);
    formData.append("email", values.email);
    formData.append("gender", values.gender);
    formData.append("phone", values.phone);
    formData.append("city", values.city);
    formData.append("district", values.district);
    formData.append("profile", values.profile);
    try {
      const res = await axios.post(`${baseUrl}/api/admin/add-user/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      dispatch(
        getCustomer({ params: "", page_number: 1, page_size: 12, status: 0, myUsers:true})
      );
      return res.data.data;
    } catch (err) {
      // console.log(err.response);
      return rejectWithValue(err.response);
    }
  } //
);

export const updateCustomer = createAsyncThunk(
  "api/admin/user-update/",
  async (values, { dispatch }) => {
    const formData = new FormData();

    formData.append("id", values.id);
    formData.append("address_id", values.address_id);
    formData.append("full_name", values.name);
    formData.append("email", values.email);
    formData.append("gender", values.gender);
    formData.append("phone", values.phone);
    formData.append("city", values.city);
    formData.append("district", values.district);
    formData.append("profile", values.profile);

    const res = await axios.put(`${baseUrl}/api/admin/user-update/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(
      getCustomer({ params: "", page_number: 1, page_size: 12, status: 0,myUsers:true })
    );
    console.log(res.data.data);
    return res.data.data;
  } //
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    loading: false,
    adding: false,
    addCustomer: null,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
    resetAddCustomerState: (state, { payload }) => {
      state.addCustomer = null;
    },
  },
  extraReducers: {
    [getCustomer.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCustomer.fulfilled]: (state, { payload }) => {
      state.getCustomer = payload;
      state.loading = false;
    },
    [getCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [addCustomer.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [addCustomer.fulfilled]: (state, { payload }) => {
      state.addCustomer = payload;
      state.loading = false;
    },
    [addCustomer.rejected]: (state, { payload }) => {
      state.addCustomer = payload;
      state.loading = false;
    },
    [updateCustomer.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateCustomer.fulfilled]: (state, { payload }) => {
      state.updateCustomer = payload;
      state.loading = false;
    },
    [updateCustomer.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const customerReducer = customerSlice.reducer;
export const { loading, adding, resetAddCustomerState } = customerSlice.actions;
