import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Image, UnorderedList } from "@chakra-ui/react";
import {
  IoHomeOutline,
  IoShirtOutline,
  IoLayersOutline,
  IoCartOutline,
  IoPeopleOutline,
  IoLogOutOutline,
  IoInformationCircleOutline,
  IoImagesOutline,
} from "react-icons/io5";
import Logo from "../../assets/img/logo.png";

import SideBarItem from "./SideBarItem";

export default function SideBar() {
  return (
    <Box pt={5} boxShadow="md" height={"100%"} zIndex={100} bg={"#FFFFFF"}>
      <UnorderedList m={0}>
        <Image p={2} src={Logo} />
        <SideBarItem
          title={"Dashboard"}
          Icon={() => <IoHomeOutline fontSize={"30px"} />}
          path={"/dashboard"}
        />
        <SideBarItem
          title={"About"}
          Icon={() => <IoInformationCircleOutline fontSize={"30px"} />}
          path={"/about"}
        />
        <SideBarItem
          title={"Products"}
          Icon={() => <IoShirtOutline fontSize={"30px"} />}
          path={"/products"}
        />
        <SideBarItem
          title={"Category"}
          Icon={() => <IoLayersOutline fontSize={"30px"} />}
          path={"/categories"}
        />
        <SideBarItem
          title={"Orders"}
          Icon={() => <IoCartOutline fontSize={"30px"} />}
          path={"/orders"}
        />
        <SideBarItem
          title={"Customers"}
          Icon={() => <IoPeopleOutline fontSize={"30px"} />}
          path={"/vendors"}
        />
        <SideBarItem
          title={"Banner"}
          Icon={() => <IoImagesOutline fontSize={"30px"} />}
          path={"/banner"}
        />
        <SideBarItem
          title={"Setting"}
          Icon={() => <SettingsIcon w="25px" h="25px" />}
          path={"/setting"}
        />
        <SideBarItem
          title={"Logout"}
          Icon={() => <IoLogOutOutline fontSize={"30px"} />}
          path={"/logout"}
        />
      </UnorderedList>
    </Box>
  );
}
