import React from "react";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";
import { FaMoneyCheck, FaKey, FaStickyNote } from "react-icons/fa";

export default function Settings() {
  const navigate = useNavigate();
  return (
    <SidebarAndNavWrapper
      props={
        <>
          {" "}
          <Box className="setting-box">
            <Box
              onClick={() => navigate("/change-password")}
              className="setting-box_component"
            >
              <FaKey size="2rem" />
              Change Password
            </Box>
            <Box
              onClick={() => navigate("/checkout-notes")}
              className="setting-box_component"
            >
              <FaStickyNote size={"2rem"} />
              Checkout Notes
            </Box>
            <Box
              onClick={() => navigate("/terms-and-conditions")}
              className="setting-box_component"
            >
              <IoDocumentText size={"2rem"} />
              Terms & Conditions
            </Box>
            <Box
              onClick={() => navigate("/refund-policy")}
              className="setting-box_component"
            >
              <FaMoneyCheck size={"2rem"} />
              Refund Policies
            </Box>
          </Box>
        </>
      }
    />
  );
}
