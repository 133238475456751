import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

// export const refreshToken = createAsyncThunk(
//   "api/refresh/",
//   async (values, thunkAPI) => {
//     const res = await axios.post(`${baseUrl}/api/refresh/`, {
//       headers: {
//         'refresh-token':
//         Authorization: `Bearer ${localStorage.getItem("access")}`,
//       },
//     });
//     console.log('',res.data.data);
//     return res.data.data;
//   }
// )

export const getCounts = createAsyncThunk(
  "api/admin/order-data/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/admin/order-data/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    console.log("", res.data.data);
    return res.data.data;
  }
);

export const changePassword = createAsyncThunk(
  "api/change-password/",
  async (values, thunkAPI) => {
    const res = await axios.put(`${baseUrl}/api/change-password/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    adding: false,
    changePasswordStatus: null,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
  },
  extraReducers: {
    [getCounts.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCounts.fulfilled]: (state, { payload }) => {
      state.getCounts = payload;
      state.loading = false;
    },
    [getCounts.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.changePasswordStatus = true;
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;
export const { loading, adding } = dashboardSlice.actions;
