import {
  Box,
  Grid,
  GridItem,
  Image,
  Collapse,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
} from "@chakra-ui/react";
import SubTitle from "../../components/SubTitle";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { categorySubAdd } from "../../redux/CategorySlice";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function AddSubCategoryModal({
  isAddModal,
  onAddClose,
  onAddOpen,
  id,
}) {
  const dispatch = useDispatch();
  const adding = useSelector((state) => state.category.categorySubAdding);

  function handleSubmit() {
    dispatch(
      categorySubAdd({
        name: document.getElementsByName("name")[0].value,
        id: id,
      })
    );
    onAddClose();
  }

  return (
    <Modal isOpen={isAddModal} onClose={onAddClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Sub-Category</ModalHeader>
        <ModalCloseButton />
        <form method="post" enctype="multipart/form-data" action="#">
          <ModalBody>
            {adding ? <Loading /> : ""}

            <SubTitle title={"Sub-category Name"} />
            <Input
              placeholder="Sub-category Name"
              name="name"
              type="text"
              mt={2}
              mb={2}
            />
          </ModalBody>

          <ModalFooter alignSelf={"center"}>
            <Button bg={"green.500"} color={"white"} onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
