import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { ModalHeader, ModalFooter, ModalBody } from "@chakra-ui/react";
import { ModalCloseButton, Button, Box, Text } from "@chakra-ui/react";
import { Input, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateInvoice } from "../../redux/OrderSlice";

export const EditInvoiceModal = ({ isOpen, onClose, orderDetail }) => {
  const { id, uuid } = useParams();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      shipping_charge: orderDetail?.shipping_charge,
      discount: orderDetail?.discount,
      payment_complete: orderDetail?.payment_complete,
      invoice_number: orderDetail?.invoice_number,
      id,
    },
    onSubmit: (values) => {
      dispatch(updateInvoice(values));
    },
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        {" "}
        <ModalContent>
          <ModalHeader>Edit Invoice</ModalHeader>
          <ModalCloseButton />{" "}
          <ModalBody>
            {" "}
            <Box mt="1rem">
              <Text fontWeight={"400"} fontSize="md" mb="0.5rem">
                Shipping Charge
              </Text>
              <Input
                id="shipping_charge"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.shipping_charge}
              />
            </Box>
            <Box mt="1rem">
              {" "}
              <Text fontWeight={"400"} fontSize="md" mb="0.5rem">
                Discount
              </Text>
              <Input
                id="discount"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.discount}
              />
            </Box>
            <FormControl display="flex" alignItems="center" mt="1rem">
              <FormLabel htmlFor="email-alerts" mb="0">
                Payment Complete?
              </FormLabel>
              <Switch
                id="payment_complete"
                isChecked={formik.values.payment_complete}
                onChange={formik.handleChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg={"green.500"}
              color={"white"}
              type="submit"
              onClick={onClose}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
