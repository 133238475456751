import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getPrivacy = createAsyncThunk(
  "api/privacy-policy/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/privacy-policy/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const updatePrivacy = createAsyncThunk(
  "api/privacy-policy/",
  async (values, thunkAPI) => {
    console.log("vv", values.privacy_policy);
    const res = await axios.patch(
      `${baseUrl}/api/privacy-policy/`,
      {
        privacy_policy: values.privacy_policy,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    if (res.status === 200) {
      window.location.reload();
    }
    return res.data.data;
  }
);

export const privacySlice = createSlice({
  name: "privacy",
  initialState: {
    loading: false,
    adding: false,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
  },
  extraReducers: {
    [getPrivacy.fulfilled]: (state, { payload }) => {
      state.privacy = payload;
    },
  },
});

export const privacyReducer = privacySlice.reducer;
// export const { loading, adding } = bannerSlice.actions;
