import { Image, Modal, ModalOverlay, Box } from "@chakra-ui/react";
import { ModalContent, ModalHeader, ModalFooter } from "@chakra-ui/react";
import { ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Button, Input, Select } from "@chakra-ui/react";
import SubTitle from "../../components/SubTitle";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { addCustomer } from "../../redux/CustomerSlice";
import { IoPersonCircleSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function AddVendorModal({ isOpen, onClose, onOpen }) {
  const [file, setFile] = React.useState();
  const dispatch = useDispatch();
  const adding = useSelector((state) => state.category.adding);

  function handleUpload(event) {
    setFile(Array.from(event.target.files));
    console.log(event.target.files);
  }
  function validateEmail(emailAdress) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the Full name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email"),
    gender: Yup.string().required("Please select one option"),
    phone: Yup.number()
      .required("Please enter your Phone numbers")
      .min(111111111, "Invalid Number")
      .max(11111111111, "Invalid Number"),

    city: Yup.string().required("Please select one city"),
    district: Yup.string().required("Please select one district"),
  });

  function handleSubmit() {
    if (
      document.getElementsByName("name")[0].value &&
      document.getElementsByName("email")[0].value &&
      document.getElementsByName("email")[0].value &&
      document.getElementsByName("phone")[0].value &&
      document.getElementsByName("city")[0].value &&
      document.getElementsByName("district")[0].value &&
      document.getElementsByName("country")[0].value &&
      file
    ) {
      if (validateEmail(document.getElementsByName("email")[0].value)) {
        dispatch(
          addCustomer({
            profile: file[0],
            name: document.getElementsByName("name")[0].value,
            email: document.getElementsByName("email")[0].value,
            gender: document.getElementsByName("gender")[0].value,
            phone: document.getElementsByName("phone")[0].value,
            city: document.getElementsByName("city")[0].value,
            district: document.getElementsByName("district")[0].value,
            country: document.getElementsByName("country")[0].value,
          })
        );
        setFile("");
        onClose();
      } else {
        toast.error("Email format is not correct");
      }
    } else {
      toast.error("Some fields are empty");
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ToastContainer />
      <ModalContent>
        <ModalHeader>New Customer</ModalHeader>
        <ModalCloseButton />
        <form method="post" enctype="multipart/form-data" action="#">
          <ModalBody>
            {adding ? <Loading /> : ""}
            <Input
              accept="image/png, image/jpeg"
              id="upfile"
              display={"none"}
              type="file"
              onChange={handleUpload}
            />
            {file ? (
              <Image
                onClick={() => document.getElementById("upfile").click()}
                src={URL.createObjectURL(file[0])}
                height={"100px"}
                width={"100px"}
                objectFit="cover"
                borderRadius={"50px"}
              />
            ) : (
              <IoPersonCircleSharp
                onClick={() => document.getElementById("upfile").click()}
                fontSize={"100px"}
              />
            )}
            <SubTitle title={"Name"} />
            <Input
              placeholder="Name"
              name="name"
              type="text"
              mt={2}
              mb={2}
              required
            />
            <SubTitle title={"Gender"} />
            <Select name="gender" required>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
            <SubTitle title={"Email"} />
            <Input
              type="email"
              placeholder="Email"
              name="email"
              mt={2}
              mb={2}
              required
            />
            <SubTitle title={"Phone"} />
            <Input
              type="phone"
              placeholder="Phone"
              name="phone"
              mt={2}
              mb={2}
              required
            />

            <SubTitle title={"Address 1"} />
            <Input
              type="text"
              placeholder="street name, zip code, state"
              name="city"
              mt={2}
              mb={2}
              required
            />

            <SubTitle title={"Address 2"} />
            <Input
              type="text"
              placeholder="street name, zip code, state"
              name="district"
              mt={2}
              mb={2}
              required
            />
            <SubTitle title={"Country"} />
            <Input
              type="text"
              placeholder="Country Name"
              name="country"
              mt={2}
              mb={2}
              required
            />
          </ModalBody>

          <ModalFooter alignSelf={"center"}>
            <Button
              bg={"green.500"}
              color={"white"}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
