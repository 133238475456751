import {
  Grid,
  GridItem,
  Input,
  InputLeftElement,
  InputGroup,
  Button,
  Center,
  Box,
  Select,
} from "@chakra-ui/react";
import {
  IoSearchOutline,
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
} from "react-icons/io5";
import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import SidebarAndNavWrapper from "../../../components/WrapperComponent/SidebarAndNavWrapper";
import { useSelector, useDispatch } from "react-redux";
import OrderListTable from "./OrderListTable";
import { useDisclosure } from "@chakra-ui/react";
import { getOrder } from "../../../redux/OrderSlice";
import Loading from "../../../components/Loading";
import { NavLink, useParams } from "react-router-dom";
import { getOrderStatus } from "../../../redux/OrderSlice";
import { refreshToken } from "../../../redux/LoginSlice";

export default function VendorOrderList() {
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const { cid } = useParams();

  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderList = useSelector((state) => state.order.getOrder);
  const loading = useSelector((state) => state.order.loading);
  const orderStatus = useSelector((state) => state.order.getOrderStatus);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [params, setParams] = useState("");
  const [status, setStatus] = useState("None");

  useEffect(() => dispatch(getOrderStatus()), []);

  useEffect(() => {
    dispatch(
      getOrder({
        page_number: pageNumber,
        page_size: pageSize,
        params: params,
        status: status,
        cid:cid,
        myUsers:'all'
      })
    );
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, [pageNumber, params]);

  useEffect(() => {
    dispatch(
      getOrder({
        page_number: 1,
        page_size: 50,
        params: "None",
        status: status,
        cid:cid,
        myUsers:'all'
      })
    );
  }, [status]);

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {loading ? <Loading /> : ""}
          <PageTitle title={"Orders"} />
          <Grid
            fontSize={"1.2rem"}
            templateColumns="repeat(3, 1fr)"
            gap={6}
            mb={5}
          >
            <GridItem>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<IoSearchOutline />}
                />
                <Input
                  value={params}
                  onChange={(e) => setParams(e.target.value)}
                  placeholder="Search Order"
                  borderRadius={"30px"}
                />
              </InputGroup>
            </GridItem>
            <GridItem>
              {
                <Select
                  onChange={(e) => setStatus(e.target.value)}
                  maxWidth={"150px"}
                  defaultValue={"None"}
                >
                  {orderStatus?.map((item) => (
                    <option value={item.id}>{item.value}</option>
                  ))}
                  <option value={"None"}>All</option>
                </Select>
              }
            </GridItem>
            <GridItem>
              <Button onClick={onOpen} float={"right"} mr={"50px"}>
                New Order
              </Button>
            </GridItem>
          </Grid>
          {orderList && <OrderListTable data={orderList.order} />}
          <Center>
            <Box textAlign={"-webkit-center"} display={"inline-flex"} mt={2}>
              {pageNumber > 1 && (
                <NavLink to="#" onClick={() => setPageNumber(pageNumber - 1)}>
                  <IoArrowBackCircleOutline fontSize={"25px"} />
                </NavLink>
              )}
              {orderList?.has_next && (
                <NavLink to="#" onClick={() => setPageNumber(pageNumber + 1)}>
                  <IoArrowForwardCircleOutline fontSize={"25px"} />
                </NavLink>
              )}
            </Box>
          </Center>
          
        </>
      }
    />
  );
}
