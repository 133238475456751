import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InputField } from "../../../../components/Form/InputField";
import { addColor } from "../../../../redux/ProductSlice";

export const AddColorModal = ({ isOpen, onClose, setRender }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const formik = useFormik({
    initialValues: {
      value: "",
      description: "",
    },
    onSubmit: (values) => {
      // console.log("values", values);
      // console.log("values.value.isEmpty()", values.value.length === 0);

      if (values.value.length === 0 || values.description.length === 0) {
        setError(true);
      } else {
        dispatch(addColor(values));
        onClose(true);
        setRender(true);
      }
    },
  });
  return (
    <>
      {/* {console.log(error)} */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={formik.handleSubmit}>
          {" "}
          <ModalContent>
            <ModalHeader>Add Color</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {" "}
              <InputField
                label="Value"
                inputId="value"
                formik={formik}
                type="text"
              />
              <InputField
                label="Hash Code"
                inputId="description"
                formik={formik}
                type="text"
              />
              {error && <Text color="red">one of the field in empty!</Text>}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit"
                // onClick={!error ? onClose : ""}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};
