import { Box,Grid, GridItem,Button } from "@chakra-ui/react";
import SubCategoryTableData from "./SubCategoryTableData";
import { useDisclosure } from "@chakra-ui/react";
import AddSubCategoryModal from "./AddSubCategoryModal";
import { IoAddCircleOutline } from "react-icons/io5";

export default function SubCategoryTable({data}){
    const { isOpen:isAddModal, onOpen:onAddOpen, onClose:onAddClose } = useDisclosure();
    return(
        <>
            <Box>
                <Grid templateColumns='repeat(8, 1fr)' gap={1} borderBottom={"1px solid lightgray"} p={2} >
                    <GridItem colSpan={6} display={'flex'}>
                        Sub Category <Button onClick={onAddOpen}><IoAddCircleOutline fontSize={"25px"} /></Button>
                    </GridItem>
                    <GridItem colSpan={2}>
                        Edit Sub-Category
                    </GridItem>
                </Grid>
            </Box>

            {
                Array.isArray(data.sub_category)?
                data.sub_category.map(item=>(
                    <SubCategoryTableData data={item}/>
                ))
                :''
            }
            
            <AddSubCategoryModal id={data.id} isAddModal={isAddModal} onAddClose={onAddClose} onAddOpen={onAddOpen} />
            
        </>
    );
}