import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getProduct = createAsyncThunk(
  "api/admin/products/",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/admin/products/?query=` +
          values.params +
          `&page_number=` +
          values.page_number +
          `&page_size=` +
          values.page_size +
          (values?.vendor != undefined ? `&vendor=${values.vendor}` : ""),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      return res.data.data;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  }
);
export const getProductDetail = createAsyncThunk(
  "/api/admin/product/:id/details/",
  async (values, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/admin/product/${values.id}/details/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);

export const updateProductColor = createAsyncThunk(
  "/api/admin/image-color/",
  async (values, thunkAPI) => {
    const res = await axios.put(
      `${baseUrl}/api/admin/image-color/`,
      {
        image: values.image,
        color: values.color,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);

export const uploadImage = createAsyncThunk(
  "/api/admin/gallery/",
  async (values, { dispatch }) => {
    const formData = new FormData();

    values.image.map((item) => formData.append("image", item));
    formData.append("product", values.product);
    formData.append("color", values.color);

    const res = await axios.post(`${baseUrl}/api/admin/gallery/`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getProductDetail({ id: values.product }));
    console.log(res.data.data);
    return res.data.data;
  }
);

export const searchProduct = createAsyncThunk(
  "/api/admin/product-search/",
  async (values, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/admin/product-search/?query=${values.text}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);

export const getColor = createAsyncThunk(
  "api/color/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/color/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    console.log(res.data.data);
    return res.data.data;
  }
);

export const getSize = createAsyncThunk(
  "api/size/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/size/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    console.log(res.data.data);
    return res.data.data;
  }
);
export const getProductReview = createAsyncThunk(
  "/api/review/ofProduct",
  async (values, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/review/?product_id=${values.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);

export const addProduct = createAsyncThunk(
  "api/admin/product/",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append("name", values.name);
      formData.append("edition", values.edition);
      formData.append("vendor", values.vendor_id);
      formData.append("product_info", values.product_info);
      formData.append("shipping_info", "shipping info");
      formData.append("cost_price", values.cost_price);
      formData.append("selling_price", values.selling_price);
      formData.append("sub_category", values.sub_category);
      formData.append("hs_code", values.hs_code);
      // formData.append("product_extra", values.product_extra);
      // values.image.map((item) => formData.append("image", item));

      const res = await axios.post(`${baseUrl}/api/admin/products/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });

      dispatch(getProduct({ page_number: 1, page_size: 6, params: "" }));
      console.log("resp", res);
      return res;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  }
);
export const addImage = createAsyncThunk(
  "api/admin/inventory/images/",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      const imageArray = [];

      values.image.map((item) => formData.append("image", item));

      formData.append("product", values.product);

      const res = await axios.post(
        `${baseUrl}/api/admin/inventory/images/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      if (res.status === 200) {
        window.location.reload();
      }
      console.log("oop", res);
      return res;

      // dispatch(getProduct({ page_number: 1, page_size: 6, params: "" }));
      // console.log(res.data.data);
      // return res;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  }
);
export const productUpdate = createAsyncThunk(
  "api/admin/products/:update/",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${baseUrl}/api/admin/products/`,
        {
          id: values.id,
          identity: values.identity,
          hs_code: values.hs_code,
          name: values.name,
          edition: values.edition,
          cost_price: values.cost_price,
          selling_price: values.selling_price,
          product_info: values.product_info,
          sub_category: values.category_id,
          vendor: values.vendor,
          is_active: values.is_active ? "True" : "False",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      dispatch(getProductDetail({ id: values.id }));
      console.log(res.data.data);
      return res.data.data;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  }
);

export const productStatus = createAsyncThunk(
  "api/product/status",
  async (id, thunkAPI) => {
    const res = await axios.put(`${baseUrl}/api/product/${id}/status/`);
    return res.data.data;
  }
);

export const addColor = createAsyncThunk(
  "api/admin/color/",
  async (values, { dispatch }) => {
    const res = await axios.post(`${baseUrl}/api/admin/color/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getColor());
    return res.data;
  }
);

export const addToInventory = createAsyncThunk(
  "api/admin/inventory/:create",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append("product", values.product);
      formData.append("size", values.size);
      formData.append("color", values.color);
      formData.append("product_limit", values.limit);
      // values.image.map((item) => formData.append("image", item));

      const res = await axios.post(
        `${baseUrl}/api/admin/inventory/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );

      dispatch(getProductDetail({ id: values.product }));
      return res.data.data;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  }
);

export const updateToInventory = createAsyncThunk(
  "api/admin/inventory/updateInventory",
  async (values, thunkAPI) => {
    const res = await axios.put(
      `${baseUrl}/api/admin/inventory/`,
      {
        id: values.id,
        product: values.product,
        size: values.size,
        color: values.color,
        product_limit: values.product_limit,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );

    console.log(res.data.data);
    return res.data.data;
  }
);

export const deleteInventory = createAsyncThunk(
  "api/admin/inventory/",
  async (values, { dispatch }) => {
    const res = await axios.delete(`${baseUrl}/api/admin/inventory/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      data: {
        id: values.id,
      },
    });

    dispatch(getProductDetail({ id: values.product }));
    console.log(res.data.data);
    return res.data.data;
  }
);

export const deleteImage = createAsyncThunk(
  "api/admin/gallery/",
  async (values, { dispatch }) => {
    const res = await axios.delete(`${baseUrl}/api/admin/gallery/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      data: {
        id: values.id,
      },
    });

    dispatch(getProductDetail({ id: values.product }));
    console.log(res.data.data);
    return res.data.data;
  }
);

export const deleteProduct = createAsyncThunk(
  "api/admin/products/:delete",
  async (values, { dispatch }) => {
    const res = await axios.delete(`${baseUrl}/api/admin/products/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      data: {
        id: values.id,
      },
    });

    dispatch(getProduct({ page_number: 1, page_size: 6, params: "" }));
    console.log(res.data.data);
    return res.data.data;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: {
    loading: false,
    adding: false,
    addInventory: [],
    productId: null,
    updateInventoryStatus: null,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
    clearAddProductData: (state) => {
      state.addProductLoading = {};
    },
    clearErrors: (state) => {
      state.addInventory = [];
    },
    resetUpdateInventoryStatus: (state) => {
      state.updateInventoryStatus = null;
    },
    resetAddProduct: (state) => {
      state.addProduct = null;
      state.getProductDetail = null;
    },
  },
  extraReducers: {
    [getProduct.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getProduct.fulfilled]: (state, { payload }) => {
      state.getProduct = payload;
      state.loading = false;
    },
    [getProduct.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [getColor.fulfilled]: (state, { payload }) => {
      state.getColor = payload;
    },

    [getSize.fulfilled]: (state, { payload }) => {
      state.getSize = payload;
    },
    [getProductReview.fulfilled]: (state, { payload }) => {
      state.productReview = payload;
    },

    [addProduct.fulfilled]: (state, { payload }) => {
      console.log("id", payload);
      state.addProduct = payload;
      state.productId = payload.data.data;
      state.addProductLoading = false;
    },
    [addProduct.pending]: (state, { payload }) => {
      state.addProductLoading = true;
    },
    [addProduct.rejected]: (state, { payload }) => {
      state.addProductLoading = {};
      state.addProduct = payload;
    },

    [addImage.fulfilled]: (state, { payload }) => {
      state.addImage = payload;
      state.productId = payload.data.data;
      state.addImageLoading = false;
    },
    [addImage.pending]: (state, { payload }) => {
      state.addImageLoading = true;
    },
    [addImage.rejected]: (state, { payload }) => {
      state.addImageLoading = {};
      state.addImage = payload;
    },
    [searchProduct.fulfilled]: (state, { payload }) => {
      state.searchProduct = payload;
    },
    [getProductDetail.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getProductDetail.pending]: (state, { payload }) => {
      state.getProductDetail = payload;
      state.loading = true;
    },
    [getProductDetail.fulfilled]: (state, { payload }) => {
      state.getProductDetail = payload;
      state.loading = false;
    },
    [updateProductColor.fulfilled]: (state, { payload }) => {
      state.updateProductColor = payload;
    },
    [uploadImage.fulfilled]: (state, { payload }) => {
      state.uploadImage = payload;
    },
    [productUpdate.fulfilled]: (state, { payload }) => {
      state.productUpdate = payload;
      state.loading = false;
    },
    [productUpdate.rejected]: (state, { payload }) => {
      state.addInventory = payload;
      state.loading = false;
    },
    [productUpdate.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [addToInventory.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [addToInventory.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [addToInventory.rejected]: (state, { payload }) => {
      state.addInventory = payload;
      state.loading = false;
    },
    [updateToInventory.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [updateToInventory.fulfilled]: (state, { payload }) => {
      state.updateInventoryStatus = true;
      state.updateToInventory = payload;
      state.loading = false;
    },
    [updateToInventory.rejected]: (state, { payload }) => {
      state.updateInventoryStatus = false;
      state.updateToInventory = payload;
      state.loading = false;
    },

    [deleteImage.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteImage.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [deleteInventory.pending]: (state, { payload }) => {
      state.deleteInventoryLoading = true;
    },
    [deleteInventory.fulfilled]: (state, { payload }) => {
      state.deleteInventoryLoading = false;
    },
    [deleteInventory.rejected]: (state, { payload }) => {
      state.deleteInventoryLoading = false;
    },

    [deleteProduct.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteProduct.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const productReducer = productSlice.reducer;
export const {
  loading,
  adding,
  clearAddProductData,
  clearErrors,
  resetUpdateInventoryStatus,
  resetAddProduct,
} = productSlice.actions;
