import { configureStore } from "@reduxjs/toolkit";
import { loginReducer } from "./LoginSlice";
import { categoryReducer } from "./CategorySlice";
import { productReducer } from "./ProductSlice";
import { persistReducer } from "redux-persist";
import { customerReducer } from "./CustomerSlice";
import { orderReducer } from "./OrderSlice";
import storage from "redux-persist/lib/storage";
import { dashboardReducer } from "./DashboardSlice";
import { aboutReducer } from "./AboutSlice";
import { bannerReducer } from "./BannerSlice";
import { termsReducer } from "./TermsSlice";
import { privacyReducer } from "./PrivacySlice";
import { notesReducer } from "./CheckoutNotesSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userAuthorization", "authenticate"],
};

const persistedReducer = persistReducer(persistConfig, loginReducer);
const persistedReducer1 = persistReducer(persistConfig, categoryReducer);
const persistedReducer2 = persistReducer(persistConfig, productReducer);
const persistedReducer3 = persistReducer(persistConfig, customerReducer);
const persistedReducer4 = persistReducer(persistConfig, orderReducer);
const persistedReducer5 = persistReducer(persistConfig, dashboardReducer);
const persistedReducer6 = persistReducer(persistConfig, aboutReducer);
const persistedReducer7 = persistReducer(persistConfig, bannerReducer);
const persistedReducer8 = persistReducer(persistConfig, termsReducer);
const persistedReducer9 = persistReducer(persistConfig, privacyReducer);
const persistedReducer10 = persistReducer(persistConfig, notesReducer);

export default configureStore({
  reducer: {
    login: persistedReducer,
    category: persistedReducer1,
    product: persistedReducer2,
    customer: persistedReducer3,
    order: persistedReducer4,
    dashboard: persistedReducer5,
    about: persistedReducer6,
    banner: persistedReducer7,
    terms: persistedReducer8,
    privacy: persistedReducer9,
    notes: persistedReducer10,
  },
  // devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false,
// });

//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
