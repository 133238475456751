import React, { useEffect } from "react";
import { Drawer, DrawerBody, DrawerFooter } from "@chakra-ui/react";
import { DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Button, Input, Box } from "@chakra-ui/react";
import { Grid, GridItem, Text, Select, InputGroup } from "@chakra-ui/react";
import { InputLeftElement, InputRightElement, Image } from "@chakra-ui/react";
import { HStack, Flex, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BsPlusLg } from "react-icons/bs";
import makeAnimated from "react-select/animated";
import PageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../redux/CategorySlice";
import {
  addImage,
  addToInventory,
  clearAddProductData,
  deleteInventory,
  resetAddProduct,
} from "../../redux/ProductSlice";
import { getColor, getSize } from "../../redux/ProductSlice";
import { NavLink } from "react-router-dom";
import { addProduct } from "../../redux/ProductSlice";
import { ToastContainer, toast } from "react-toastify";
import { AddColorModal } from "./ProductDetail.js/Component/AddColorModal";
import {
  IoClose,
  IoCloudUploadOutline,
  IoTrashBinOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { getCustomer } from "../../redux/CustomerSlice";

export default function AddProductDrawer({
  isOpen,
  onClose,
  btnRef,
  setValue,
  value,
}) {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.category.getCategory);
  const customerList = useSelector((state) => state.customer.getCustomer);
  const color = useSelector((state) => state.product.getColor);
  const size = useSelector((state) => state.product.getSize);
  const addProducts = useSelector((state) => state.product.addProduct);
  const product = useSelector((state) => state.product);
  const productId = useSelector((state) => state.product.productId);

  const addProductLoading = useSelector(
    (state) => state.product.addProductLoading
  );
  const [inventorydata, setInventorydata] = useState([]);
  const [file, setFile] = React.useState();
  const [render, setRender] = useState(false);
  console.log("prod", product);
  const {
    isOpen: isOpenColorModal,
    onOpen: onOpenColorModal,
    onClose: onCloseColorModal,
  } = useDisclosure();

  let modal = true;
  console.log("add", addProducts);
  // function handleUpload(event) {
  //   setFile(Array.from(event.target.files));
  //   console.log(event.target.files);
  // }
  const [files, setFiles] = useState([]);

  const handleUpload = (e) => {
    const selectedFiles = Array.from(e.target.files);

    selectedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryData = event.target.result; // This is the binary data
        // You can do something with the binary data here, like sending it to a server or processing it further.
      };

      reader.readAsArrayBuffer(file);
    });

    // If you want to keep track of the selected files, you can still set them in the state.
    setFiles([...files, ...selectedFiles]);
  };
  console.log("files", files);

  const removeImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const addItemToInventory = (color, size, count, file) => {
    if (parseInt(count)) {
      console.log("count", count);
      if (count >= 1) {
        dispatch(
          addToInventory({
            limit: parseInt(count),
            size: parseInt(size),
            color: parseInt(color),
            product: parseInt(product.productId),
            // image: file,
          })
        );
        document.getElementById("color").value = "";
        document.getElementById("size").value = "";
        document.getElementById("count").value = "";
        setFile(null);
      } else {
        toast.error("Stock cannot be smaller than 1");
      }
    } else {
      toast.error("Stock should be valid number.");
    }
  };

  const removeInventory = (index) => {
    setInventorydata(inventorydata.filter((item, i) => i !== index));
  };

  const handleSubmit = () => {
    if (
      document.getElementsByName("product_name")[0].value === "" ||
      value === "" ||
      document.getElementsByName("cost_price")[0].value === "" ||
      document.getElementsByName("selling_price")[0].value === "" ||
      document.getElementsByName("edition")[0].value === "" ||
      document.getElementsByName("hs_code")[0].value === "" ||
      document.getElementsByName("category")[0].value === ""
      // JSON.stringify(inventorydata) === "[]" ||
      // !file
    ) {
      toast.error("Some Fields are missing!");
    } else {
      dispatch(
        addProduct({
          name: document.getElementsByName("product_name")[0].value,
          vendor_id: document.getElementsByName("vendor_id")[0].value,
          edition: document.getElementsByName("edition")[0].value,
          product_info: value,
          shipping_info: "",
          cost_price: document.getElementsByName("cost_price")[0].value,
          selling_price: document.getElementsByName("selling_price")[0].value,
          sub_category: parseInt(
            document.getElementsByName("category")[0].value
          ),
          hs_code: document.getElementsByName("hs_code")[0].value,
          // product_extra: JSON.stringify(inventorydata),
          // image: file,
        })
      );

      setFile("");
    }
  };
  const handleImage = () => {
    console.log("files", files);
    dispatch(
      addImage({
        image: files,
        product: productId,
      })
    );
  };
  console.log("files", files);
  useEffect(() => {
    dispatch(
      getCustomer({
        page_number: 1,
        page_size: 100,
        params: "",
        status: 0,
        myUsers: true,
      })
    );
    dispatch(getCategory());
    dispatch(getColor());
    dispatch(getSize());
  }, [render]);

  useEffect(() => {
    // console.log('zz',Object.keys(addProducts)[0]);
    if (addProducts?.status === 400) {
      toast.error(addProducts?.data[Object.keys(addProducts?.data)[0]][0]);
    }
    if (addProducts?.status === 200) {
      toast.success(addProducts?.data.message);
      // dispatch(resetAddProduct());
    }

    if (addProducts?.status >= 500) {
      toast.error("Something went wrong!");
    }
  }, [addProducts]);
  console.log("Add", addProducts);
  useEffect(() => {
    // console.log('zz',Object.keys(addProducts)[0]);
    if (product?.addInventory?.status === 400) {
      toast.error(product.addInventory.data.message);
    }

    if (addProducts?.status >= 500) {
      toast.error("Something went wrong!");
    }
  }, [product.addInventory]);

  // logic is used to for closing the "Add product" model
  if (addProductLoading === false) {
    console.log("nicee");
    // onClose();
    dispatch(clearAddProductData());
  }

  const handleDeleteInventory = (id) => {
    dispatch(
      deleteInventory({
        id: id,
        product: product.productId,
      })
    );
  };

  return (
    <Drawer
      isOpen={modal ? isOpen : modal}
      placement="right"
      size={"lg"}
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay>
        <ToastContainer />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody pt={10}>
            <PageTitle title={"Add Product"} />

            <Box height={"1px"} width={"100%"} bg={"#afbac0"} mb={5}></Box>

            <Grid templateColumns="repeat(2, 1fr)" gap={5}>
              <GridItem colSpan={2}>
                <Text mb={2}>Select Vendor</Text>
                <Select
                  name="vendor_id"
                  placeholder="Select option"
                  disabled={addProducts?.status === 200 ? "disabled" : ""}
                >
                  {Array.isArray(customerList?.users)
                    ? customerList?.users?.map((item) => (
                        <option value={item.id}>{item.full_name}</option>
                      ))
                    : ""}
                </Select>
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>Select Category</Text>
                <Select
                  name="category"
                  placeholder="Select option"
                  disabled={addProducts?.status === 200 ? "disabled" : ""}
                >
                  {Array.isArray(categoryList)
                    ? categoryList.map((item) =>
                        Array.isArray(item.sub_category)
                          ? item.sub_category.map((it) => (
                              // console.log(item.name + ' - ' + it.name)
                              <option value={it.id}>
                                {item.name + " - " + it.name}
                              </option>
                            ))
                          : ""
                      )
                    : ""}
                </Select>
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>HS Code*</Text>
                <Input
                  name="hs_code"
                  placeholder="HS Code"
                  disabled={addProducts?.status === 200 ? "disabled" : ""}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>Name*</Text>
                <Input
                  name="product_name"
                  placeholder="Product Name"
                  disabled={addProducts?.status === 200 ? "disabled" : ""}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>Edition*</Text>
                <Input
                  name="edition"
                  placeholder="Edition"
                  disabled={addProducts?.status === 200 ? "disabled" : ""}
                />
              </GridItem>

              <GridItem colSpan={2}>
                <Text mb={2}>Description</Text>
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  readOnly={addProducts?.status === 200 ? true : false}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>Cost Price*</Text>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="$"
                  />
                  <Input
                    name="cost_price"
                    placeholder="Price"
                    type="number"
                    disabled={addProducts?.status === 200 ? "disabled" : ""}
                  />
                </InputGroup>
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>Selling Price*</Text>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children="$"
                  />
                  <Input
                    name="selling_price"
                    placeholder="Price"
                    type="number"
                    disabled={addProducts?.status === 200 ? "disabled" : ""}
                  />
                </InputGroup>
              </GridItem>

              <GridItem colSpan={2} justifySelf={"end"}>
                <DrawerFooter ml="4rem">
                  {/* <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button> */}
                  <Button
                    onClick={handleSubmit}
                    color="blue"
                    disabled={addProducts?.status === 200 ? "disabled" : ""}
                  >
                    Submit
                  </Button>
                </DrawerFooter>
              </GridItem>

              <GridItem colSpan={2}>
                <Flex justifyContent={"space-between"}>
                  <Text>Inventory</Text>
                  <Box>
                    <HStack onClick={onOpenColorModal} cursor={"pointer"}>
                      <Text>Color</Text>
                      <BsPlusLg />
                    </HStack>

                    <AddColorModal
                      isOpen={isOpenColorModal}
                      onClose={onCloseColorModal}
                      setRender={setRender}
                    />
                  </Box>
                </Flex>
              </GridItem>

              <GridItem colSpan={2}>
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  {/* <GridItem colSpan={1}>
                    <Text mb={2}>Upload Image</Text>
                    <Input
                      name="images"
                      onChange={handleUpload}
                      accept="image/png, image/jpeg"
                      id="upfile"
                      display={"none"}
                      type="file"
                      multiple
                    />

                    <Button
                      // right={"15px"}
                      // position={"absolute"}
                      onClick={() => document.getElementById("upfile").click()}
                      w="7rem"
                      mb="1rem"
                    >
                      <IoCloudUploadOutline fontSize={"25px"} />
                    </Button>
                    {file
                      ? file.map((item) => (
                          <GridItem colSpan={1}>
                            <Image
                              src={URL.createObjectURL(item)}
                              objectFit={"cover"}
                              height={"40px"}
                              width={"100%"}
                              borderRadius={"4px"}
                            />
                          </GridItem>
                        ))
                      : ""}
                  </GridItem> */}

                  <GridItem colSpan={1}>
                    <Text mb={2}>Color</Text>
                    <Select placeholder="Select" id="color">
                      {Array.isArray(color)
                        ? color.map((item) => (
                            <option value={item.id}>{item.value}</option>
                          ))
                        : ""}
                    </Select>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Size</Text>
                    <Select placeholder="Select" id="size">
                      {Array.isArray(size)
                        ? size.map((item) => (
                            <option value={item.id}>{item.value}</option>
                          ))
                        : ""}
                    </Select>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Limit</Text>
                    <Input placeholder="count" type="number" id="count" />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Button
                      onClick={() =>
                        addItemToInventory(
                          document.getElementById("color").value,
                          document.getElementById("size").value,
                          document.getElementById("count").value,
                          file
                        )
                      }
                      mt={"30px"}
                    >
                      Add
                    </Button>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem colSpan={2}>
                {product?.getProductDetail?.product_extra.map((extra, i) => (
                  <Grid templateColumns="repeat(4, 1fr)" gap={5}>
                    {/* <GridItem colSpan={1}>
                      <Image
                        src={product?.getProductDetail?.images[i]?.images}
                        objectFit={"contain"}
                        height={"40px"}
                        width={"100%"}
                        borderRadius={"2px"}
                        mb="1.5rem"
                      />
                    </GridItem> */}

                    <GridItem colSpan={1}>
                      <Text mb={2}>{extra.color}</Text>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Text mb={2}>{extra.size}</Text>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Text mb={2}>x {extra.product_limit}</Text>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <NavLink
                        to="#"
                        onClick={() => {
                          handleDeleteInventory(extra.id);
                        }}
                      >
                        <IoTrashOutline fontSize={"30px"} />
                      </NavLink>
                    </GridItem>
                  </Grid>
                ))}
              </GridItem>
              <GridItem colSpan={1}>
                {/* <Text mb={2}>Upload Images</Text> */}
                <Input
                  name="images"
                  onChange={handleUpload}
                  accept="image/png, image/jpeg"
                  id="upfile"
                  display={"none"}
                  type="file"
                  multiple
                />

                <Button
                  onClick={() => document.getElementById("upfile").click()}
                  w="8rem"
                  mb="1rem"
                >
                  Upload Images
                </Button>
                <Flex gap="0.5rem">
                  {files.map((item, index) => (
                    <Box key={index} position="relative">
                      <Image
                        src={URL.createObjectURL(item)}
                        objectFit="cover"
                        height="100px"
                        width="100px"
                      />
                      <Button
                        position="absolute"
                        top="0px"
                        right="0px"
                        borderRadius={"0px"}
                        height={"1.5rem"}
                        onClick={() => removeImage(index)}
                        // bgColor="white"
                      >
                        <IoClose />
                      </Button>
                    </Box>
                  ))}
                </Flex>
              </GridItem>
            </Grid>
            <Button
              mt="1rem"
              mb="2rem"
              onClick={handleImage}
              color="blue"
              disabled={!files.length > 0 ? "disabled" : ""}
            >
              Submit
            </Button>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
