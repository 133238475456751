import { Box, Grid, GridItem, Image, Collapse } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { ModalHeader, ModalFooter, ModalBody } from "@chakra-ui/react";
import { ModalCloseButton, Button, Input } from "@chakra-ui/react";
import { IoTrashOutline, IoPencilOutline } from "react-icons/io5";
import { IoChevronUp, IoChevronDown } from "react-icons/io5";
import { useDisclosure } from "@chakra-ui/react";
import SubCategoryTable from "./SubCategoryTable";
import SubTitle from "../../components/SubTitle";
import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { categoryUpdate } from "../../redux/CategorySlice";
import { confirmAlert } from "react-confirm-alert";
import { categoryDelete } from "../../redux/CategorySlice";

export default function CategoryTableData({ data }) {
  const dispatch = useDispatch();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isOpenModal, onOpen, onClose } = useDisclosure();

  const [file, setFile] = React.useState(null);

  function handleUpload(event) {
    setFile(event.target.files[0]);
  }

  const formik = useFormik({
    initialValues: {
      name: data.name,
    },
    onSubmit: (values) => {
      dispatch(categoryUpdate({ id: data.id, name: values.name, icon: file }));
      onClose();
    },
  });

  const handleDelete = () => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(categoryDelete({ id: data.id })),
        },
        {
          label: "Cancel",
          onClick: () => console.log("Cancelled"),
        },
      ],
    });
  };

  return (
    <>
      <Box>
        <Grid
          fontSize={"sm"}
          _hover={{ bg: "#f6f6f6" }}
          templateColumns="repeat(8, 1fr)"
          gap={1}
          borderBottom={"1px solid lightgray"}
          p={2}
        >
          <GridItem colSpan={1}>
            <Box boxShadow="md" width={"fit-content"} p={1} borderRadius={3}>
              <Image
                objectFit={"contain"}
                src={data.icon}
                height={"50px"}
                width={"50px"}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={5} alignSelf={"center"}>
            {data.name}
          </GridItem>
          <GridItem display={"inline-flex"} alignItems={"center"}>
            {isOpen ? (
              <IoChevronUp fontSize={"20px"} onClick={onToggle} />
            ) : (
              <IoChevronDown fontSize={"20px"} onClick={onToggle} />
            )}
            <Box
              ml={2}
              boxShadow="md"
              height={"fit-content"}
              width={"fit-content"}
              p={2}
              borderRadius={3}
            >
              <IoPencilOutline fontSize={"20px"} onClick={onOpen} />
            </Box>
            <Box
              ml={2}
              boxShadow="md"
              height={"fit-content"}
              width={"fit-content"}
              p={2}
              borderRadius={3}
            >
              <IoTrashOutline onClick={handleDelete} fontSize={"20px"} />
            </Box>
          </GridItem>
        </Grid>

        {/* Collapse */}
        <Collapse in={isOpen} animateOpacity>
          <Box p={5} color="black" bg="#F2F2F2" rounded="md" shadow="md">
            <SubCategoryTable data={data} />
          </Box>
        </Collapse>

        {/* Edit Modal */}
        <Modal isOpen={isOpenModal} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Category</ModalHeader>
            <ModalCloseButton />
            <form onSubmit={formik.handleSubmit}>
              <ModalBody>
                <SubTitle title={"Category Icon"} />

                <Box
                  boxShadow="md"
                  width={"fit-content"}
                  p={1}
                  borderRadius={3}
                  mt={2}
                  mb={2}
                >
                  {file ? (
                    <Image height={"100px"} src={URL.createObjectURL(file)} />
                  ) : (
                    <Image src={data.icon} height={"50px"} width={"50px"} />
                  )}
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleUpload}
                    name="image"
                  ></input>
                </Box>

                <Box
                  boxShadow="md"
                  width={"fit-content"}
                  p={1}
                  borderRadius={3}
                  mt={2}
                  mb={2}
                ></Box>
                <SubTitle title={"Category Name"} />
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  id="name"
                  placeholder="Category Name"
                  type="text"
                  mt={2}
                  mb={2}
                />
              </ModalBody>

              <ModalFooter alignSelf={"center"}>
                <Button type="submit" bg={"green.500"} color={"white"}>
                  Save
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
