import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProductReview } from "../../../../redux/ProductSlice";
import { Grid, GridItem, Text, Select, Input, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { DynamicStar } from "react-dynamic-star";

export const Review = () => {
  const dispatch = useDispatch();
  const productReview = useSelector((state) => state.product.productReview);

  return (
    <>
      {productReview?.map((review) => {
        return (
          <Box>
            {" "}
            <Text> {review.comment}</Text>
            <Box mb="-4rem">
              <DynamicStar
                width={19}
                h={2}
                rating={review?.rating}
                totalStars={5}
                emptyStarColor="#DCDCDC"
              />
            </Box>
            <hr />
          </Box>
        );
      })}
    </>
  );
};
