import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const loginUser = createAsyncThunk(
  "api/login-user/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/admin/superadmin-login/`, {
      email: values.email,
      password: values.password,
    });
    console.log(res);
    return res.data.data;
  } //
);
export const refreshToken = createAsyncThunk(
  "api/refresh/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/refresh/`, values);
    return res.data.data;
  } //
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    authenticate: false,
    loading: false,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    resetLogin: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.authenticate = false;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      state.checkOutStatus = "done";
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);

      state.userAuthorization = payload;
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      localStorage.setItem("access", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);

      state.userAuthorization = payload;
    },
  },
});

export const loginReducer = loginSlice.reducer;
export const { loading, resetLogin } = loginSlice.actions;
