import {
  Grid,
  GridItem,
  Input,
  InputLeftElement,
  InputGroup,
  Button,
  Box,
  Heading,
  Center,
} from "@chakra-ui/react";
import {
  IoSearchOutline,
  IoArrowForwardCircleOutline,
  IoArrowBackCircleOutline,
} from "react-icons/io5";

import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../redux/ProductSlice";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import Loading from "../../components/Loading";
import PageTitle from "../../components/PageTitle";
import ProductListTable from "../Product/ProductListTable";
import { NavLink } from "react-router-dom";
import AddProductDrawer from "../Product/AddProductDrawer";
import BannerListTable from "./BannerListTable";
import { getBanner } from "../../redux/BannerSlice";
import AddVendorModal from "../Vendor/AddVendorModal";
import AddBannerModel from "./AddBannerModel";
import { refreshToken } from "../../redux/LoginSlice";

export default function Banner() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const productList = useSelector((state) => state.product.getProduct);
  const banner = useSelector((state) => state.banner.banner);
  console.log("banner", banner);
  const loading = useSelector((state) => state.product.loading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [params, setParams] = useState("");
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    dispatch(
      getProduct({
        page_number: pageNumber,
        page_size: pageSize,
        params: params,
      })
    );
    dispatch(getBanner());
    setReRender(false);
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, [pageNumber, params, reRender]); //

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {loading ? <Loading /> : ""}
          <Box>
            <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={5}>
              {/* <GridItem>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children={<IoSearchOutline />}
                  />
                  <Input
                    onChange={(e) => setParams(e.target.value)}
                    placeholder="Search Products"
                    value={params}
                    borderRadius={"30px"}
                  />
                </InputGroup>
              </GridItem> */}
              <GridItem>
                <PageTitle title={"Banner"} />
              </GridItem>
              <GridItem></GridItem>
              <GridItem>
                <Button
                  ref={btnRef}
                  onClick={onOpen}
                  float={"right"}
                  mr={"50px"}
                >
                  Add Banner
                </Button>
              </GridItem>
            </Grid>
            {productList ? (
              <BannerListTable setReRender={setReRender} banner={banner} />
            ) : (
              "No Data"
            )}

            <AddBannerModel isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          </Box>
          {/* <Box onClick={onClose}>nice</Box> */}
        </>
      }
    />
  );
}
