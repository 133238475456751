import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import Loading from "../../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { Box, Text, Input, Grid, Link } from "@chakra-ui/react";
import { GridItem, Image, Button, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getAbout,
  resetSuccessStatus,
  updateAboutGallery,
} from "../../redux/AboutSlice";
import { updateAbout } from "../../redux/AboutSlice";
import { refreshToken } from "../../redux/LoginSlice";

export default function About() {
  const dispatch = useDispatch();
  const toast = useToast();
  const loading = useSelector((state) => state.about.loading);
  const getAboutData = useSelector((state) => state.about.getAbout);
  const about = useSelector((state) => state.about);
  const [value, setValue] = useState("z");
  const [file, setFile] = React.useState();
  const [aboutImage2, setAboutImage2] = React.useState();
  const [aboutImage3, setAboutImage3] = React.useState();
  const [file1, setFile1] = React.useState();
  const [file2, setFile2] = React.useState();
  const [file3, setFile3] = React.useState();
  const [chargeListfile, setChargeListfile] = React.useState();

  useEffect(() => {
    dispatch(getAbout());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  useEffect(() => {
    setValue(getAboutData?.description);
  }, [getAboutData]);

  function handleUpload(event) {
    setFile(Array.from(event.target.files));
  }

  function handleAboutImageUpload2(e, id) {
    setAboutImage2(Array.from(e.target.files));
    dispatch(updateAboutGallery({ id, image: Array.from(e.target.files)[0] }));
  }
  function handleAboutImageUpload3(e, id) {
    setAboutImage3(Array.from(e.target.files));
    dispatch(updateAboutGallery({ id, image: Array.from(e.target.files)[0] }));
  }

  function handleUpload1(e, id) {
    setFile1(Array.from(e.target.files));
    dispatch(updateAboutGallery({ id, image: Array.from(e.target.files)[0] }));
  }

  function handleUpload2(event) {
    setFile2(Array.from(event.target.files));
  }

  function handleUpload3(event) {
    setFile3(Array.from(event.target.files));
  }

  function handleChargeListUpload(event) {
    setChargeListfile(Array.from(event.target.files));
  }

  const validationSchema = Yup.object({
    email1: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email1"),
    email2: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email2"),
    contact1: Yup.number()
      .min(111111111, "Invalid Number")
      .max(11111111111, "Invalid Number")
      .required("Please enter your contact1"),
    contact2: Yup.number()
      .min(111111111, "Invalid Number")
      .max(11111111111, "Invalid Number"),
  });

  useEffect(() => {
    if (about?.successStatus === true) {
      toast({
        title: "Success",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      dispatch(resetSuccessStatus());
    } else if (about?.successStatus === false) {
      toast({
        title: "Not Success",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }
  }, [about?.successStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      site_name: getAboutData?.site_name,
      contact1: getAboutData?.contact1,
      contact2: getAboutData?.contact2,
      location1: getAboutData?.location1,
      location2: getAboutData?.location2,
      facebook_link: getAboutData?.facebook_link,
      instagram_link: getAboutData?.instagram_link,
      youtube_link: getAboutData?.youtube_link,
      email1: getAboutData?.email1,
      email2: getAboutData?.email2,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(
        updateAbout({
          site_name: values.site_name,
          contact1: values.contact1,
          contact2: values.contact2,
          location1: values.location1,
          location2: values.location2,
          facebook_link: values.facebook_link,
          instagram_link: values.instagram_link,
          youtube_link: values.youtube_link,
          email1: values.email1,
          email2: values.email2,
          description: value,
          logo: file ? file[0] : getAboutData?.logo,
          // about_image: file1 ? file1[0] : getAboutData?.about_image,
          login_image: file2 ? file2[0] : getAboutData?.login_image,
          signup_image: file3 ? file3[0] : getAboutData?.signup_image,
          charge_list: chargeListfile
            ? chargeListfile[0]
            : getAboutData?.charge_list,
        })
      );
    },
  });

  return (
    <>
      <SidebarAndNavWrapper
        props={
          <>
            {loading ? <Loading /> : ""}

            <Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem colSpan={2}>
                    <Input
                      accept="image/png, image/jpeg"
                      id="upfile"
                      display={"none"}
                      type="file"
                      onChange={handleUpload}
                    />
                    {file ? (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile").click()
                        }
                        src={URL.createObjectURL(file[0])}
                        height={"100px"}
                        width={"100px"}
                        objectFit="cover"
                        borderRadius={"50px"}
                      />
                    ) : (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile").click()
                        }
                        src={getAboutData?.logo}
                        height={"100px"}
                        width={"100px"}
                        objectFit="cover"
                        borderRadius={"50px"}
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Site Name</Text>
                    <Input
                      id="site_name"
                      value={formik.values.site_name}
                      onChange={formik.handleChange}
                      placeholder="site_name"
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text mb={2}>About Nepalayan Handicraft</Text>
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={setValue}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Contact 1</Text>
                    <Input
                      id="contact1"
                      value={formik.values.contact1}
                      onChange={formik.handleChange}
                      placeholder="contact number"
                    />
                    <Box>
                      {formik.errors.contact1 ? (
                        <Box style={{ color: "red" }}>
                          {formik.errors.contact1}
                        </Box>
                      ) : null}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Contact 2</Text>
                    <Input
                      id="contact2"
                      value={formik.values.contact2}
                      onChange={formik.handleChange}
                      placeholder="contact number"
                    />
                    <Box>
                      {formik.errors.contact2 ? (
                        <Box style={{ color: "red" }}>
                          {formik.errors.contact2}
                        </Box>
                      ) : null}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Email 1</Text>
                    <Input
                      id="email1"
                      value={formik.values.email1}
                      onChange={formik.handleChange}
                      placeholder="Email"
                    />
                    <Box>
                      {formik.errors.email1 ? (
                        <Box style={{ color: "red" }}>
                          {formik.errors.email1}
                        </Box>
                      ) : null}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Email 2</Text>
                    <Input
                      id="email2"
                      value={formik.values.email2}
                      onChange={formik.handleChange}
                      placeholder="Email"
                    />
                    <Box>
                      {formik.errors.email2 ? (
                        <Box style={{ color: "red" }}>
                          {formik.errors.email2}
                        </Box>
                      ) : null}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Address 1</Text>
                    <Input
                      id="location1"
                      value={formik.values.location1}
                      onChange={formik.handleChange}
                      placeholder="Address 1"
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Address 2</Text>
                    <Input
                      id="location2"
                      value={formik.values.location2}
                      onChange={formik.handleChange}
                      placeholder="Address 2"
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Facebook Url</Text>
                    <Input
                      id="facebook_link"
                      value={formik.values.facebook_link}
                      onChange={formik.handleChange}
                      placeholder="facebook"
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>Insta Url</Text>
                    <Input
                      id="instagram_link"
                      value={formik.values.instagram_link}
                      onChange={formik.handleChange}
                      placeholder="insta"
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text mb={2}>youtube Url</Text>
                    <Input
                      id="youtube_link"
                      value={formik.values.youtube_link}
                      onChange={formik.handleChange}
                      placeholder="youtube"
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Text mb={2}>About Image 1</Text>
                    <Input
                      accept="image/png, image/jpeg"
                      id="upfile1"
                      display={"none"}
                      type="file"
                      onChange={(e) =>
                        handleUpload1(e, getAboutData?.about_image[0].id)
                      }
                    />
                    {file1 ? (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile1").click()
                        }
                        src={URL.createObjectURL(file1[0])}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    ) : (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile1").click()
                        }
                        src={getAboutData?.about_image[0].image}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>About Image 2</Text>
                    <Input
                      accept="image/png, image/jpeg"
                      id="aboutImage2"
                      display={"none"}
                      type="file"
                      onChange={(e) =>
                        handleAboutImageUpload2(
                          e,
                          getAboutData?.about_image[1].id
                        )
                      }
                    />
                    {aboutImage2 ? (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("aboutImage2").click()
                        }
                        src={URL.createObjectURL(aboutImage2[0])}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    ) : (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("aboutImage2").click()
                        }
                        src={getAboutData?.about_image[1].image}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text mb={2}>About Image 3</Text>
                    <Input
                      accept="image/png, image/jpeg"
                      id="aboutImage3"
                      display={"none"}
                      type="file"
                      onChange={(e) =>
                        handleAboutImageUpload3(
                          e,
                          getAboutData?.about_image[2].id
                        )
                      }
                    />
                    {aboutImage3 ? (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("aboutImage3").click()
                        }
                        src={URL.createObjectURL(aboutImage3[0])}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    ) : (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("aboutImage3").click()
                        }
                        src={getAboutData?.about_image[2].image}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    )}
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Text mb={2}>Login Image</Text>
                    <Input
                      accept="image/png, image/jpeg"
                      id="upfile2"
                      display={"none"}
                      type="file"
                      onChange={handleUpload2}
                    />
                    {file2 ? (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile2").click()
                        }
                        src={URL.createObjectURL(file2[0])}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    ) : (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile2").click()
                        }
                        src={getAboutData?.login_image}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    )}
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Text mb={2}>Signup Image</Text>
                    <Input
                      accept="image/png, image/jpeg"
                      id="upfile3"
                      display={"none"}
                      type="file"
                      onChange={handleUpload3}
                    />
                    {file3 ? (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile3").click()
                        }
                        src={URL.createObjectURL(file3[0])}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    ) : (
                      <Image
                        border={"1px solid black"}
                        onClick={() =>
                          document.getElementById("upfile3").click()
                        }
                        src={getAboutData?.signup_image}
                        height={"200px"}
                        width={"200px"}
                        objectFit="cover"
                      />
                    )}
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Text mb={2}>Charge List</Text>
                    <Input
                      accept=".pdf"
                      id="chargeListUpload"
                      display={"none"}
                      type="file"
                      onChange={handleChargeListUpload}
                    />
                    {chargeListfile ? (
                      <Link
                        href={URL.createObjectURL(chargeListfile[0])}
                        isExternal
                        textDecoration={"underline"}
                        color="blue"
                      >
                        Charge list pdf link
                      </Link>
                    ) : (
                      <>
                        <Link
                          href={getAboutData?.charge_list}
                          isExternal
                          textDecoration={"underline"}
                          color="blue"
                          mr="1rem"
                        >
                          Charge list pdf link
                        </Link>
                        <Button
                          onClick={() =>
                            document.getElementById("chargeListUpload").click()
                          }
                        >
                          Upload pdf
                        </Button>
                      </>
                    )}
                  </GridItem>

                  <GridItem ml="30%">
                    <Text mb={2} height={"23px"}></Text>
                    <Button
                      type="submit"
                      width={"100%"}
                      bg={"green.500"}
                      _hover={{ bg: "green.400" }}
                      color={"white"}
                    >
                      Save
                    </Button>
                  </GridItem>
                </Grid>
              </form>
            </Box>
          </>
        }
      />
    </>
  );
}
