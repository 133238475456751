import React from "react";
import { Button, Spinner } from "@chakra-ui/react";

export const SaveButton = ({ type, onClick, loading }) => {
  return (
    <Button
      ml="2rem"
      mt="5rem"
      type={type ?? "submit"}
      colorScheme="green"
      size="sm"
      px="1.5rem"
      minW="6rem"
      h="2rem"
      shadow="base"
      _active={{
        transform: "scale(.97) !important",
      }}
      onClick={onClick}
    >
      {loading && <Spinner size="sm" color="#fff" marginRight="2" />}
      Save
    </Button>
  );
};
