import { Box, Button, Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { Heading, Image, Select } from "@chakra-ui/react";
import Logo from "../../assets/img/logo.png";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUpdateInvoiceStatus,
  getOrderInvoice,
} from "../../redux/OrderSlice";
import Loading from "../../components/Loading";
import { useEffect, useRef } from "react";
import { getOrderStatus } from "../../redux/OrderSlice";
import PageTitle from "../../components/PageTitle";
import { changeOrderStatus } from "../../redux/OrderSlice";
import { refreshToken } from "../../redux/LoginSlice";
import { useReactToPrint } from "react-to-print";
import { EditInvoiceModal } from "./EditInvoiceModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

export default function OrderDetail() {
  const { id, uuid } = useParams();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let subtotal = 0;
  let costPriceSubtotal = 0;

  const orderDetail = useSelector((state) => state.order.getOrderInvoice);
  const orderStatus = useSelector((state) => state.order.getOrderStatus);
  const loading = useSelector((state) => state.order.loading);
  const invoiceStatus = useSelector((state) => state.order.updateInvoiceStatus);

  orderDetail?.items?.map(
    (item, i) =>
      (subtotal =
        parseFloat(subtotal) +
        parseFloat(item.selling_price / 1.13) * item.quantity)
  );
  orderDetail?.items?.map(
    (item, i) =>
      (costPriceSubtotal =
        parseFloat(costPriceSubtotal) +
        parseFloat((item.cost_price / 1.13) * item.quantity))
  );
  useEffect(() => {
    if (invoiceStatus?.status === 200) {
      toast.success(invoiceStatus.data.message);
      dispatch(clearUpdateInvoiceStatus());
    } else if (invoiceStatus?.status >= 200) {
      toast.error(invoiceStatus.data.message);
      dispatch(clearUpdateInvoiceStatus());
    }
  }, [invoiceStatus]);

  useEffect(() => {
    dispatch(getOrderInvoice({ id: id }));
    dispatch(getOrderStatus());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  const sellinPriceRef = useRef();
  const costPriceRef = useRef();
  const handleSellingPriceInvoicePrint = useReactToPrint({
    content: () => sellinPriceRef.current,
  });

  const handleCostPriceInvoicePrint = useReactToPrint({
    content: () => costPriceRef.current,
  });

  return (
    <SidebarAndNavWrapper
      props={
        <>
          <ToastContainer />
          {loading ? <Loading /> : ""}
          <Button onClick={onOpen}>Edit Invoice</Button>
          {orderDetail && (
            <EditInvoiceModal
              isOpen={isOpen}
              onClose={onClose}
              orderDetail={orderDetail}
            />
          )}

          <Grid templateColumns="repeat(4, 1fr)" p={5}>
            <GridItem>
              <PageTitle title={"Selling Price Invoice "} />
            </GridItem>
            <GridItem></GridItem>
            <GridItem textAlign={"right"} mr={4}></GridItem>
            <GridItem>
              <label>Change Order Status</label>
              {orderDetail && (
                <Select
                  onChange={(e) =>
                    dispatch(
                      changeOrderStatus({
                        uuid: uuid,
                        status_id: e.target.value,
                      })
                    )
                  }
                  defaultValue={orderDetail.status.id}
                  maxWidth={"150px"}
                >
                  {orderStatus?.map((item) => (
                    <option value={item.id}>{item.value}</option>
                  ))}
                </Select>
              )}
            </GridItem>
          </Grid>
          <Grid templateColumns="1fr" w="97%">
            <GridItem justifySelf={"end"}>
              {" "}
              <Button onClick={handleSellingPriceInvoicePrint}>
                Print Invoice
              </Button>
            </GridItem>
          </Grid>

          <Box m={10} boxShadow="base" ref={sellinPriceRef}>
            <Grid templateColumns="repeat(2, 1fr)" p={5}>
              <GridItem mb={2}>
                <Image src={Logo} />
              </GridItem>
              <GridItem mb={2}>
                <Heading textAlign={"right"} fontSize={"3xl"}>
                  INVOICE
                </Heading>
              </GridItem>
              <GridItem colSpan={2} mb={4}>
                Nepalayan Handicrafts <br></br> Thamel, Kathmandu, Nepal
              </GridItem>
              <GridItem mb={4} colSpan={2}>
                <Grid
                  fontSize={"13px"}
                  templateColumns="repeat(4, 1fr)"
                  p={4}
                  bg={"#f6f6f6"}
                >
                  <GridItem>
                    Nepalayan Handicrafts <br></br> Thamel, Kathmandu, Nepal
                  </GridItem>
                  <GridItem textAlign={"right"} pr={5}>
                    Invoice# <br></br> {orderDetail?.invoice_number}
                  </GridItem>
                  <GridItem>
                    Invoice Date <br></br> {orderDetail?.invoice_date}
                  </GridItem>
                  <GridItem>
                    Invoice Amount <br></br> Nrs. {orderDetail?.invoice_amount}
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem fontSize={"13px"} colSpan={2}>
                <Grid
                  mb={2}
                  color={"gray"}
                  templateColumns="repeat(8, 1fr)"
                  borderBottom={"1px solid lightgray"}
                >
                  <GridItem>SN</GridItem>
                  <GridItem>Product</GridItem>
                  <GridItem>Size</GridItem>
                  <GridItem>Color</GridItem>
                  <GridItem>Unit Price</GridItem>
                  <GridItem>Freq</GridItem>
                  <GridItem>Comment</GridItem>

                  <GridItem textAlign={"right"} mr={"10px"}>
                    Total(Exclusive Tax)
                  </GridItem>
                </Grid>
                {orderDetail?.items?.map((item, i) => (
                  <Grid mb={2} templateColumns="repeat(8, 1fr)">
                    <GridItem>{i + 1}</GridItem>
                    <GridItem>{item.product}</GridItem>
                    <GridItem>{item?.size.value}</GridItem>
                    <GridItem>{item?.color.value}</GridItem>
                    <GridItem>
                      {Math.round(item.selling_price / 1.13).toFixed(2)}
                    </GridItem>
                    <GridItem>x{item.quantity}</GridItem>
                    <GridItem>{item.comment}</GridItem>
                    <GridItem textAlign={"right"} mr={"10px"}>
                      {((item.selling_price / 1.13) * item.quantity).toFixed(2)}
                    </GridItem>
                  </Grid>
                ))}
              </GridItem>
              <GridItem
                p={5}
                colSpan={2}
                borderBottom={"1px solid lightgray"}
              ></GridItem>
              <GridItem></GridItem>
              <GridItem fontSize={"13px"}>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Subtotal</GridItem>
                  <GridItem>Nrs.{subtotal?.toFixed(2)}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Tax Amount</GridItem>
                  <GridItem>Nrs.{(subtotal * 0.13)?.toFixed(2)}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Discount</GridItem>
                  <GridItem>Nrs.{orderDetail?.discount}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Shipping Charge </GridItem>
                  <GridItem>Nrs.{orderDetail?.shipping_charge}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Total</GridItem>
                  <GridItem>
                    Nrs.{orderDetail?.invoice_amount?.toFixed(2)}
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Box>

          <PageTitle title={"Cost Price Invoice "} />
          <Grid templateColumns="1fr" w="97%">
            <GridItem justifySelf={"end"}>
              {" "}
              <Button onClick={handleCostPriceInvoicePrint}>
                Print Invoice
              </Button>
            </GridItem>
          </Grid>
          <Box m={10} boxShadow="base" ref={costPriceRef}>
            <Grid templateColumns="repeat(2, 1fr)" p={5}>
              <GridItem mb={2}>
                <Image src={Logo} />
              </GridItem>
              <GridItem mb={2}>
                <Heading textAlign={"right"} fontSize={"3xl"}>
                  ORDER SHEET
                </Heading>
              </GridItem>
              <GridItem colSpan={2} mb={4}>
                Nepalayan Handicrafts <br></br> Thamel, Kathmandu, Nepal
              </GridItem>
              <GridItem mb={4} colSpan={2}>
                <Grid
                  fontSize={"13px"}
                  templateColumns="repeat(4, 1fr)"
                  p={4}
                  bg={"#f6f6f6"}
                >
                  <GridItem>
                    Nepalayan Handicrafts <br></br> Thamel, Kathmandu, Nepal
                  </GridItem>
                  <GridItem textAlign={"right"} pr={5}>
                    Invoice# <br></br> {orderDetail?.invoice_number}
                  </GridItem>
                  <GridItem>
                    Invoice Date <br></br> {orderDetail?.invoice_date}
                  </GridItem>
                  <GridItem>
                    Invoice Amount <br></br> Nrs.{" "}
                    {costPriceSubtotal +
                      costPriceSubtotal * 0.13 +
                      orderDetail?.shipping_charge -
                      parseFloat(orderDetail?.discount)}
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem fontSize={"13px"} colSpan={2}>
                <Grid
                  mb={2}
                  color={"gray"}
                  templateColumns="repeat(9, 1fr)"
                  borderBottom={"1px solid lightgray"}
                >
                  <GridItem>SN</GridItem>
                  <GridItem>Product</GridItem>
                  <GridItem>Size</GridItem>
                  <GridItem>Color</GridItem>
                  <GridItem>Product</GridItem>
                  <GridItem>Unit Price</GridItem>
                  <GridItem>Freq</GridItem>
                  <GridItem>Comment</GridItem>
                  <GridItem textAlign={"right"} mr={"10px"}>
                    Total
                  </GridItem>
                </Grid>
                {orderDetail?.items?.map((item, i) => (
                  <Grid mb={2} templateColumns="repeat(9, 1fr)" mt="3rem">
                    <GridItem p={2}>{i + 1}</GridItem>
                    <GridItem p={2}>{item.product}</GridItem>
                    <GridItem p={2}>{item?.size.value}</GridItem>
                    <GridItem p={2}>{item?.color.value}</GridItem>
                    <GridItem>
                      <Image
                        src={item.image}
                        h="5rem"
                        w="5rem"
                        borderRadius={"0.4rem"}
                        objectFit="contain"
                      />
                    </GridItem>
                    <GridItem p={2}>
                      {(item.cost_price / 1.13).toFixed(2)}
                    </GridItem>
                    <GridItem p={2}>x{item.quantity}</GridItem>
                    <GridItem p={2}>{item.comment}</GridItem>
                    <GridItem textAlign={"right"} mr={"10px"} p={2}>
                      {((item.cost_price / 1.13) * item.quantity).toFixed(0)}
                    </GridItem>
                  </Grid>
                ))}
              </GridItem>
              <GridItem
                p={5}
                colSpan={2}
                borderBottom={"1px solid lightgray"}
              ></GridItem>
              <GridItem></GridItem>
              <GridItem fontSize={"13px"}>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Subtotal</GridItem>
                  <GridItem>Nrs.{costPriceSubtotal?.toFixed(2)}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Tax Amount</GridItem>
                  <GridItem>
                    Nrs.{(costPriceSubtotal * 0.13)?.toFixed(2)}
                  </GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Discount</GridItem>
                  <GridItem>Nrs.{orderDetail?.discount}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Shipping Charge </GridItem>
                  <GridItem>Nrs.{orderDetail?.shipping_charge}</GridItem>
                </Grid>
                <Grid textAlign={"right"} templateColumns="repeat(2, 1fr)">
                  <GridItem>Total</GridItem>

                  <GridItem>
                    Nrs.
                    {(
                      costPriceSubtotal +
                      costPriceSubtotal * 0.13 +
                      orderDetail?.shipping_charge -
                      parseFloat(orderDetail?.discount)
                    ).toFixed(2)}
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Box>
        </>
      }
    />
  );
}
