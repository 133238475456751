import {
  Grid,
  GridItem,
  Box,
  Image,
  Button,
  Collapse,
  HStack,
} from "@chakra-ui/react";
import { IoTrashOutline, IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useDisclosure } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { deleteProduct, productStatus } from "../../redux/ProductSlice";
import Moment from "react-moment";
import { IoPencilOutline, IoEyeSharp } from "react-icons/io5";
import UpdateBannerModel from "./UpdateBannerModel";
import { deleteBanner } from "../../redux/BannerSlice";

export default function BannerTableData({
  data,
  setReRender,
  hidingCostPrice,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  useEffect(() => {}, [dispatch]);

  const handleDelete = () => {
    confirmAlert({
      title: " Are you sure you want to delete this Banner?",
      message: "Hope you know what you are doing!",
      buttons: [
        {
          label: "Ok",
          onClick: () => dispatch(deleteBanner({ id: data.id })),
        },
        {
          label: "Cancel",
          onClick: () => console.log("cancelled"),
        },
      ],
    });
  };

  return (
    <Grid templateColumns="1fr 2fr 2fr 2fr  2fr">
      <GridItem>
        <Box boxShadow="md" width={"fit-content"} p={1} borderRadius={3}>
          <Image
            src={data.image}
            height={"50px"}
            width={"50px"}
            objectFit="cover"
          />
        </Box>
      </GridItem>
      <GridItem alignSelf={"center"}>{data.title}</GridItem>
      <GridItem alignSelf={"center"} width="90%" lineHeight={"17px"}>
        {data.sub_title}
      </GridItem>

      <GridItem alignSelf={"center"}>
        {" "}
        {<Moment date={data.created_on} format="LLL" />}
      </GridItem>
      <GridItem alignSelf={"center"}>
        <HStack>
          {" "}
          <IoPencilOutline
            onClick={onOpen}
            alignSelf={"center"}
            fontSize="20px"
            cursor={"pointer"}
          />
          <IoTrashOutline
            fontSize={"30px"}
            onClick={handleDelete}
            cursor="pointer"
          />
        </HStack>
      </GridItem>
      <UpdateBannerModel
        data={data}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </Grid>
  );
}
