import { Grid, GridItem, Input, InputLeftElement } from "@chakra-ui/react";
import { InputGroup, Button, Center, Box } from "@chakra-ui/react";
import {
  IoSearchOutline,
  IoArrowForwardCircleOutline,
  IoArrowBackCircleOutline,
} from "react-icons/io5";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";
import AddVendorModal from "./AddVendorModal";
import { getCustomer, resetAddCustomerState } from "../../redux/CustomerSlice";
import CustomerListTable from "./CustomerListTable";
import { NavLink } from "react-router-dom";
import Loading from "../../components/Loading";
import { refreshToken } from "../../redux/LoginSlice";
import { ToastContainer, toast } from "react-toastify";

export default function VendorList() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const customerList = useSelector((state) => state.customer.getCustomer);
  const loading = useSelector((state) => state.customer.loading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [params, setParams] = useState("");
  const customer = useSelector((state) => state.customer);
  const [myUsers, setMyUsers] = useState(true);

  useEffect(() => {
    dispatch(
      getCustomer({
        page_number: pageNumber,
        page_size: pageSize,
        params: params,
        status: 0,
        myUsers:myUsers
      })
    );
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, [pageNumber, params,myUsers]);

  useEffect(() => {
    if (customer?.addCustomer?.status === 400) {
      toast.error(customer.addCustomer.data.message);
    }

    resetAddCustomerState();
  }, [customer.addCustomer]);

  return (
    <SidebarAndNavWrapper
      props={
        <>
          <ToastContainer />
          {loading ? <Loading /> : ""}
          <PageTitle title={"Customers"} />
          <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={5}>
            <GridItem>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children={<IoSearchOutline />}
                />
                <Input
                  onChange={(e) => setParams(e.target.value)}
                  placeholder="Search Vendor"
                  borderRadius={"30px"}
                />
              </InputGroup>
            </GridItem>
            <GridItem></GridItem>
            <GridItem>
              
              <Button onClick={()=>setMyUsers(!myUsers)} float={"right"} mr={"10px"}>
                {myUsers ? "My Users" : "Other Users"}
              </Button>
              <Button onClick={onOpen} float={"right"} mr={"10px"}>
                New Vendor
              </Button>
            </GridItem>
          </Grid>

          <CustomerListTable data={customerList} />

          <Center>
            <Box textAlign={"-webkit-center"} display={"inline-flex"} mt={2}>
              {pageNumber > 1 && (
                <NavLink to="#" onClick={() => setPageNumber(pageNumber - 1)}>
                  <IoArrowBackCircleOutline fontSize={"25px"} />
                </NavLink>
              )}
              {customerList?.has_next && (
                <NavLink to="#" onClick={() => setPageNumber(pageNumber + 1)}>
                  <IoArrowForwardCircleOutline fontSize={"25px"} />
                </NavLink>
              )}
            </Box>
          </Center>

          <AddVendorModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </>
      }
    />
  );
}
