import { Grid, GridItem } from "@chakra-ui/react";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import SidebarAndNavWrapper from "../../../components/WrapperComponent/SidebarAndNavWrapper";
import { useParams } from "react-router-dom";
import { getProductDetail } from "../../../redux/ProductSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCategory } from "../../../redux/CategorySlice";
import { getColor, updateProductColor } from "../../../redux/ProductSlice";
import ProductDetailUpdate from "./ProductDetailUpdate.js";
import { uploadImage } from "../../../redux/ProductSlice";
import { SliderCard } from "./Component/SliderCard";
import { deleteImage } from "../../../redux/ProductSlice";
import Loading from "../../../components/Loading";
import { refreshToken } from "../../../redux/LoginSlice";

export default function ProductDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const productDetail = useSelector((state) => state.product.getProductDetail);
  const product = useSelector((state) => state.product);
  const colorList = useSelector((state) => state.product.getColor);
  const [render, setRender] = useState(false);
  const [specificTapIndex, setSpecificTapIndex] = useState(0);

  useEffect(() => {
    dispatch(getProductDetail({ id: id }));
    dispatch(getCategory());
    dispatch(getColor());
    setRender(false);
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, [render]);

  const changeColor = (id, imageId) => {
    dispatch(updateProductColor({ image: imageId, color: id })); //
  };

  function handleUpload(event) {
    let ColorIndex;

    colorList.some((color, index) => {
      ColorIndex = index;
      return color.value === "No Color";
    });
    if (Array.from(event.target.files).length >= 1) {
      dispatch(
        uploadImage({
          product: id,
          image: Array.from(event.target.files),
          color: colorList[ColorIndex].id,
        })
      );
    }
  }

  const deleteTheImage = (imageId) => {
    dispatch(deleteImage({ id: imageId, product: id }));
  };

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {product.loading ? <Loading /> : ""}

          <Grid templateColumns="repeat(9, 1fr)" gap={2}>
            <GridItem colSpan={4}>
              <SliderCard
                deleteTheImage={deleteTheImage}
                handleUpload={handleUpload}
                specficImage={productDetail?.images}
                changeColor={changeColor}
                colorList={colorList}
              />
            </GridItem>
            <GridItem colSpan={5}>
              {productDetail?.id === parseInt(id) ? (
                <ProductDetailUpdate
                  productDetail={productDetail}
                  setRender={setRender}
                  setSpecificTapIndex={setSpecificTapIndex}
                  specificTapIndex={specificTapIndex}
                />
              ) : (
                ""
              )}
            </GridItem>
          </Grid>
        </>
      }
    />
  );
}
