import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle";
import SubTitle from "../../components/SubTitle";
import {
  FaCaravan,
  FaCalendarWeek,
  FaCalendarDay,
  FaMoneyBillAlt,
  FaMoneyBillWave,
} from "react-icons/fa";
import Loading from "../../components/Loading";
import { getCounts } from "../../redux/DashboardSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { refreshToken } from "../../redux/LoginSlice";

export default function Dashboard() {
  const dispatch = useDispatch();
  const counts = useSelector((state) => state.dashboard.getCounts);
  const loading = useSelector((state) => state.dashboard.loading);

  useEffect(() => {
    dispatch(getCounts());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, []);

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {loading ? <Loading /> : ""}
          <Grid templateColumns="repeat(8, 1fr)" gap={1} p={2}>
            <GridItem colSpan={8}>
              <PageTitle title="Dashboard" />
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(10, 1fr)" gap={4} p={2}>
            <GridItem color="maroon" colSpan={{ base: 2, md: 2, sm: 4 }}>
              <Box p={5} boxShadow={"lg"} borderRadius={10}>
                <FaCaravan fontSize={"50px"} style={{ marginBottom: "10px" }} />
                <SubTitle title={"Ship Today"} />
                <Heading mt={2} fontSize={"2xl"}>
                  {counts?.ship2today}
                </Heading>
              </Box>
            </GridItem>

            <GridItem colSpan={{ base: 2, md: 2, sm: 4 }}>
              <Box p={5} boxShadow={"lg"} borderRadius={10}>
                <FaCalendarDay
                  fontSize={"50px"}
                  style={{ marginBottom: "10px" }}
                />
                <SubTitle title={"Today Order"} />
                <Heading mt={2} fontSize={"2xl"}>
                  {counts?.today_order}
                </Heading>
              </Box>
            </GridItem>

            <GridItem colSpan={{ base: 2, md: 2, sm: 4 }}>
              <Box p={5} boxShadow={"lg"} borderRadius={10}>
                <FaCalendarWeek
                  fontSize={"50px"}
                  style={{ marginBottom: "10px" }}
                />
                <SubTitle title={"This Week Order"} />
                <Heading mt={2} fontSize={"2xl"}>
                  {counts?.order_week}
                </Heading>
              </Box>
            </GridItem>
          </Grid>
          {/* <Box w="30%" mt="3%" ml="1%">
            <PasswordChange />
          </Box> */}
        </>
      }
    />
  );
}
