import { Box,Center, Heading, Image } from "@chakra-ui/react";
import box from '../assets/img/box.png';

export default function NoData({title}){
    return(
        <Box textAlign={'center'}>
            <Center>
                <Image src={box} height={'100px'}/>
            </Center>
            <Heading fontSize={'xl'}>{title}</Heading>
        </Box>
        
    )
}