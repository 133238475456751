import { Grid, GridItem, Box, Image, Button, Collapse } from "@chakra-ui/react";
import { IoTrashOutline, IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useDisclosure } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import { deleteProduct, productStatus } from "../../redux/ProductSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function ProductTableData({
  data,
  setReRender,
  hidingCostPrice,
}) {
  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch();
  useEffect(() => {}, [dispatch]);

  const handleDelete = () => {
    confirmAlert({
      title: " Are you sure you want to delete this product?",
      message: "Hope you know what you are doing!",
      buttons: [
        {
          label: "Ok",
          onClick: () => dispatch(deleteProduct({ id: data.id })),
        },
        {
          label: "Cancel",
          onClick: () => console.log("cancelled"),
        },
      ],
    });
  };

  return (
    <Grid fontSize={"1.2rem"} templateColumns="repeat(10, 1fr)" gap={3} boxShadow="sm" mb={2} _hover={{bg:'#f6f6f6'}} borderRadius="10px">
      <GridItem>
        <NavLink to={"/productdetail/" + data.id + "/"}>
          <Box  width={"fit-content"} p={1} borderRadius={3}>
            <Image src={data.icon} height={"50px"} width={"50px"} />
          </Box>
        </NavLink>
      </GridItem>
      <GridItem colSpan="2" alignSelf={"center"} fontSize={'sm'} textOverflow="ellipsis" overflow={'hidden'} whiteSpace="nowrap">
        <NavLink to={"/productdetail/" + data.id + "/"}>{data.name}</NavLink>
      </GridItem>
      <GridItem colSpan="2" alignSelf={"center"} fontSize={'sm'}>{data.category}</GridItem>
      <GridItem alignSelf={"center"} fontSize={'sm'}>{data.sub_category}</GridItem>
      <GridItem alignSelf={"center"} fontSize={'sm'}>{data.selling_price}</GridItem>
      <GridItem alignSelf={"center"} fontSize={'sm'}>
        {hidingCostPrice ? "XXX" : data.cost_price}
      </GridItem>

      <GridItem alignSelf={"center"} fontSize={'sm'}>
        <Button
          colorScheme={data.status ? "teal" : "red"}
          variant="solid"
          fontSize={'sm'}
          onClick={() => {
            setReRender(true);
            dispatch(productStatus(data.id));
          }}
        >
          {data.status ? "Active" : "Inactive"}
        </Button>
      </GridItem>
      <GridItem display={"inline-flex"} alignItems={"center"}>
        <Box
          ml={2}
          boxShadow="md"
          height={"fit-content"}
          width={"fit-content"}
          p={2}
          borderRadius={3}
        >
          <IoTrashOutline onClick={handleDelete} fontSize={"20px"} />
        </Box>
      </GridItem>
    </Grid>
  );
}
