import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

function RequireAuth({ props }) {
  const navigate = useNavigate();
  const authed = useSelector((state) => state.login.authenticate);
  return authed ? props : <Navigate to="/" />;
}

export default RequireAuth;
