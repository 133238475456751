import { Grid,GridItem,Box } from "@chakra-ui/react";
import SideBar from "../SideBar/SideBar";
import Nav from "../Nav";

export default function SidebarAndNavWrapper({props}){
    return (
        <Grid templateColumns='repeat(12, 1fr)' >
          <GridItem colSpan={1} w='100%' h='100%'>
            
          <SideBar />
          
          </GridItem>
          <GridItem colSpan={11}  w='100%' h='100%' >
            <Nav />

            <Box p={"16px"}>
            {props}
            </Box>
          </GridItem>
        </Grid>
    );
}