import { Box, Grid, GridItem } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ProductDetail from "./pages/Product/ProductDetail.js/ProductDetail";
import ProductList from "./pages/Product/ProductList";
import CategoryList from "./pages/Category/CategoryList";
import Login from "./pages/Login/Login";
import RequireAuth from "./components/WrapperComponent/RequireAuth";
import OrderList from "./pages/Order/OrderList";
import VendorList from "./pages/Vendor/VendorList";
import "react-toastify/dist/ReactToastify.css";
import OrderDetail from "./pages/Order/OrderDetail";
import Logout from "./pages/Login/Logout";
import Dashboard from "./pages/Dashboard/Dashboard";
import About from "./pages/About/About";
import Banner from "./pages/Banner/Banner";
import { PasswordChange } from "./pages/Setting/PasswordChange";
import VendorOrderList from "./pages/Vendor/VendorOrders/OrderList";
import VendorProductList from "./pages/Vendor/VendorProducts/ProductList";
import Settings from "./pages/Setting/Settings";
import Terms from "./pages/Setting/Terms";
import PrivacyPolicies from "./pages/Setting/PrivacyPolicies";
import CheckoutNotes from "./pages/Setting/CheckoutNotes";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route
            path="/dashboard"
            element={<RequireAuth props={<Dashboard />} />}
            exact
          />
          <Route
            path="/about"
            element={<RequireAuth props={<About />} />}
            exact
          />
          <Route
            path="/products"
            element={<RequireAuth props={<ProductList />} />}
            exact
          />
          <Route
            path="/productdetail/:id/"
            element={<RequireAuth props={<ProductDetail />} />}
            exact
          />
          <Route
            path="/categories"
            element={<RequireAuth props={<CategoryList />} />}
            exact
          />
          <Route
            path="/orders"
            element={<RequireAuth props={<OrderList />} />}
            exact
          />
          <Route
            path="/order/:cid/"
            element={<RequireAuth props={<VendorOrderList />} />}
            exact
          />
          <Route
            path="/order/:id/detail/:uuid/"
            element={<RequireAuth props={<OrderDetail />} />}
            exact
          />
          <Route
            path="/vendors"
            element={<RequireAuth props={<VendorList />} />}
            exact
          />
          <Route
            path="/vendors/products/:cid/"
            element={<RequireAuth props={<VendorProductList />} />}
            exact
          />
          <Route
            path="/banner"
            element={<RequireAuth props={<Banner />} />}
            exact
          />
          <Route
            path="/setting"
            element={<RequireAuth props={<Settings />} />}
            exact
          />
          <Route
            path="/logout"
            element={<RequireAuth props={<Logout />} />}
            exact
          />
          <Route
            path="/change-password"
            element={<RequireAuth props={<PasswordChange />} />}
            exact
          />
          <Route
            path="/terms-and-conditions"
            element={<RequireAuth props={<Terms />} />}
            exact
          />
          <Route
            path="/refund-policy"
            element={<RequireAuth props={<PrivacyPolicies />} />}
            exact
          />
          <Route
            path="/checkout-notes"
            element={<RequireAuth props={<CheckoutNotes />} />}
            exact
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
