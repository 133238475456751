import { Box, Grid, GridItem, HStack, Text } from "@chakra-ui/react";
import ProductTableData from "./ProductTableData";
import { IoPencilOutline, IoEyeSharp } from "react-icons/io5";
import { useState } from "react";
export default function ProductListTable({ data, setReRender }) {
  const [hidingCostPrice, setHidingCostPrice] = useState(true);
  return (
    <>
      <Box>
        {/* Table Heading */}
        <Grid
          templateColumns="repeat(10, 1fr)"
          gap={3}
          borderBottom={"1px solid lightgray"}
          p={2}
          mb={2}
          fontSize={"1rem"}
        >
          <GridItem>Image</GridItem>
          <GridItem colSpan="2">Name</GridItem>
          <GridItem colSpan="2">Category</GridItem>
          <GridItem>Sub-Category</GridItem>
          <GridItem>Selling Price</GridItem>
          <GridItem>
            <HStack>
              <Text> Cost Price</Text>

              <IoEyeSharp
                alignSelf={"center"}
                fontSize="20px"
                cursor={"pointer"}
                onClick={() => {
                  setHidingCostPrice(!hidingCostPrice);
                }}
              />
            </HStack>
          </GridItem>

          <GridItem>Status</GridItem>
          <GridItem>Action</GridItem>
        </Grid>
      </Box>

      {Array.isArray(data?.products)
        ? data?.products?.map((item) => (
            <ProductTableData
              data={item}
              setReRender={setReRender}
              hidingCostPrice={hidingCostPrice}
            />
          ))
        : ""}
    </>
  );
}
