import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
} from "@chakra-ui/react";
import Logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/LoginSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { getAbout } from "../../redux/AboutSlice";

export default function Login() {
  const dispatch = useDispatch();

  const authed = useSelector((state) => state.login.authenticate);
  const about = useSelector((state) => state.about);
  let navigate = useNavigate();
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (dashboard.changePasswordStatus) {
      toast.success("Password changed successfully!");
      document.location.reload();
    }
    dispatch(getAbout());
  }, [dashboard.changePasswordStatus]);

  const handleSubmit = () => {
    const email = document.getElementsByName("email")[0].value;
    const password = document.getElementsByName("password")[0].value;
    if (email && password.length > 3) {
      dispatch(loginUser({ email, password }));
    }
  };

  if (authed) {
    return <Navigate to="/products" />;
  } else
    return (
      <Box
        height={"100vh"}
        backgroundImage={`url(${about?.getAbout?.login_image})`}
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
      >
        <ToastContainer />
        <Center>
          <Box mt={"100px"} bg="white" width={"70%"} borderRadius={"15px"}>
            <Grid templateColumns="repeat(2, 1fr)" p="1rem" columnGap={"1rem"}>
              <GridItem
                colSpan={{ base: 1, md: 1, sm: 2 }}
                display={{ base: "block", md: "block", sm: "none" }}
              >
                <Image
                  src={about?.getAbout?.login_image}
                  borderRadius={"10px"}
                  // mt="0.7rem"
                  // w="100%"
                  h="100%"
                />
              </GridItem>

              <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                <Image src={Logo} mb={4} />
                <Heading textAlign={"center"} fontSize={"xl"} mb={4}>
                  ADMIN LOGIN
                </Heading>

                <label>Email</label>
                <Input name="email" placeholder="Email" type="email" mb={4} />

                <label>Password</label>
                <Input
                  name="password"
                  placeholder="password"
                  type="password"
                  mb={4}
                />

                <Button
                  onClick={handleSubmit}
                  float={"right"}
                  width={"200px"}
                  colorScheme="teal"
                  variant="solid"
                >
                  Login
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </Center>
      </Box>
    );
}
