import { Grid, GridItem, Box, Image } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoPencilOutline, IoEyeSharp } from "react-icons/io5";
import UpdateVendorModal from "./UpdateVendorModal";

export default function CustomerTableData({ data }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  console.log("data", data);
  return (
    <>
      <Grid
        boxShadow={"sm"}
        fontSize={"sm"}
        p="2"
        mb={"2"}
        borderRadius="10px"
        _hover={{ bg: "#f6f6f6" }}
        templateColumns="repeat(14, 1fr)"
        gap={1}
      >
        <GridItem
          alignSelf={"center"}
          onClick={() => navigate(`/vendors/products/${data.id}/`)}
        >
          <NavLink to={`/vendors/products/${data.id}/`}> {data.id}</NavLink>
        </GridItem>
        <GridItem
          alignSelf={"center"}
          onClick={() => navigate("/vendors/products/${data.id}/")}
        >
          <NavLink to={`/vendors/products/${data.id}/`}>
            {" "}
            <Image
              height={"30px"}
              width={"30px"}
              mb={1}
              borderRadius={"15px"}
              objectFit="cover"
              src={data.profile}
            />
          </NavLink>
        </GridItem>
        <GridItem
          alignSelf={"center"}
          colSpan={4}
          onClick={() => navigate(`/vendors/products/${data.id}/`)}
        >
          <NavLink to={`/vendors/products/${data.id}/`}>
            {" "}
            {data.full_name}
          </NavLink>
        </GridItem>
        <GridItem
          alignSelf={"center"}
          colSpan={2}
          onClick={() => navigate(`/vendors/products/${data.id}/`)}
        >
          <NavLink to={`/vendors/products/${data.id}/`}>View Products</NavLink>
        </GridItem>
        <GridItem
          alignSelf={"center"}
          colSpan={3}
          onClick={() => navigate(`/vendors/products/${data.id}/`)}
        >
          {data.email}
        </GridItem>
        <GridItem
          alignSelf={"center"}
          colSpan={2}
          onClick={() => navigate(`/vendors/products/${data.id}/`)}
        >
          {data.phone}
        </GridItem>
        <GridItem>
          {data.added_by_admin ? (
            <IoPencilOutline
              style={{ cursor: "pointer" }}
              onClick={onOpen}
              alignSelf={"center"}
              fontSize="20px"
            />
          ) : (
            <IoEyeSharp onClick={onOpen} alignSelf={"center"} fontSize="20px" />
          )}
        </GridItem>
      </Grid>

      <UpdateVendorModal
        data={data}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
}
