import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getTerms = createAsyncThunk(
  "api/terms-condition/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/terms-condition/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const updateTerms = createAsyncThunk(
  "api/terms-condition/",
  async (values, thunkAPI) => {
    console.log("vv", values.terms_condition);
    const res = await axios.patch(
      `${baseUrl}/api/terms-condition/`,
      {
        terms_conditions: values.terms_condition,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    if (res.status === 200) {
      window.location.reload();
    }

    return res.data.data;
  }
);

export const termsSlice = createSlice({
  name: "terms",
  initialState: {
    loading: false,
    adding: false,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
  },
  extraReducers: {
    [getTerms.fulfilled]: (state, { payload }) => {
      state.term = payload;
    },
  },
});

export const termsReducer = termsSlice.reducer;
// export const { loading, adding } = bannerSlice.actions;
