import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getCategory = createAsyncThunk(
  "api/admin/category/get",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/admin/category/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);

export const categoryAdd = createAsyncThunk(
  "api/admin/category/add",
  async (values, { dispatch }) => {
    const formData = new FormData();

    formData.append("icon", values.icon, values.icon.name);
    formData.append("name", values.name);
    formData.append("description", values.name);
    formData.append("sub_category", values.sub_category);

    const res = await axios.post(`${baseUrl}/api/admin/category/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getCategory());
    console.log(res.data.data);
    return res.data.data;
  } //
);

export const categoryUpdate = createAsyncThunk(
  "api/admin/category/update",
  async (values, { dispatch }) => {
    console.log(values);

    const formData = new FormData();

    formData.append("id", values.id);
    if (values.icon !== null) {
      formData.append("icon", values.icon);
    }
    formData.append("name", values.name);
    formData.append("description", values.name);

    const res = await axios.put(`${baseUrl}/api/admin/category/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    console.log(res.data.data);
    dispatch(getCategory());
    return res.data.data;
  } //
);

export const categoryDelete = createAsyncThunk(
  "api/admin/category/delete/cat/",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.delete(`${baseUrl}/api/admin/category/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
        data: {
          id: values.id,
          status: 0,
        },
      });
      console.log(res.data.data);
      dispatch(getCategory());
      return res.data.data;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  } //
);

export const categorySubAdd = createAsyncThunk(
  "api/admin/sub-categories/add",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/admin/sub-categories/`,
        {
          name: values.name,
          category: values.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      console.log(res.data.data);
      dispatch(getCategory());
      return res.data.data;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  } //
);

export const categorySubUpdate = createAsyncThunk(
  "api/admin/sub-categories/update",
  async (values, { dispatch }) => {
    const res = await axios.put(
      `${baseUrl}/api/admin/sub-categories/`,
      {
        name: values.name,
        id: values.id,
        description: values.description,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    dispatch(getCategory());
    return res.data.data;
  } //
);

export const categorySubDelete = createAsyncThunk(
  "api/admin/sub-categories/delete",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.delete(`${baseUrl}/api/admin/sub-categories/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
        data: {
          id: values.id,
          status: 0,
        },
      });
      console.log(res.data.data);
      dispatch(getCategory());
      return res.data.data;
    } catch (err) {
      console.log(rejectWithValue(err.response));
      return rejectWithValue(err.response);
    }
  } //
);

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    loading: false,
    adding: false,
    addCatyegoryLoading: {},
    categoryDelete: null,
    categorySubDelete: null,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
    clearCategoryDel: (state, { payload }) => {
      state.categoryDelete = [];
      state.categorySubDelete = [];
    },
  },
  extraReducers: {
    [categoryAdd.pending]: (state, { payload }) => {
      state.adding = true;
    },
    [categoryAdd.fulfilled]: (state, { payload }) => {
      state.adding = false;
    },
    [categoryAdd.rejected]: (state, { payload }) => {
      state.adding = false;
    },
    [categoryUpdate.pending]: (state, { payload }) => {
      state.adding = true;
    },
    [categoryUpdate.fulfilled]: (state, { payload }) => {
      state.adding = false;
    },
    [categoryUpdate.rejected]: (state, { payload }) => {
      state.adding = false;
    },
    [getCategory.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.getCategory = payload;
      state.loading = false;
    },
    [getCategory.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [categorySubAdd.pending]: (state, { payload }) => {
      state.categorySubAdding = true;
    },
    [categorySubAdd.fulfilled]: (state, { payload }) => {
      state.categorySubAdding = false;
    },
    [categorySubAdd.rejected]: (state, { payload }) => {
      state.categorySubAdding = false;
    },

    [categorySubUpdate.pending]: (state, { payload }) => {
      state.adding = true;
    },
    [categorySubUpdate.fulfilled]: (state, { payload }) => {
      state.adding = false;
    },
    [categorySubUpdate.rejected]: (state, { payload }) => {
      state.adding = false;
    },
    [categorySubDelete.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [categorySubDelete.rejected]: (state, { payload }) => {
      state.categorySubDelete = payload;
    },
    [categoryDelete.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [categoryDelete.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [categoryDelete.rejected]: (state, { payload }) => {
      state.categoryDelete = payload;
      state.loading = false;
    },
  },
});

export const categoryReducer = categorySlice.reducer;
export const {
  loading,
  adding,
  addCatyegoryLoading,
  clearAddCategoryData,
  clearCategoryDel,
} = categorySlice.actions;
