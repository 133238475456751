import { Box, Center, ListItem } from "@chakra-ui/react";
import { NavLink,useLocation } from "react-router-dom";
import SubTitle from "../SubTitle";

export default function SideBarItem({title,Icon,path}){
    const location = useLocation();
    console.log();
    return(
        <Box
            _hover={{
                background: "white",
                color: "teal.500",
                boxShadow: 'rgba(100, 100, 111, 0.1) 0px 7px 29px 0px'
            }}

            color={location.pathname.includes(path)?"teal.500":"#1a202c"}
        >
            <NavLink to={path}>
                <ListItem listStyleType={"none"} textAlign={"center"} pt={5} pb={5}>
                    <Center>
                        <Icon fontSize={"30px"}/>
                    </Center>
                    <SubTitle title={title} />
                </ListItem>
            </NavLink>
        </Box>
    );
}