import { ModalOverlay, ModalContent } from "@chakra-ui/react";
import { ModalHeader, ModalFooter, Modal } from "@chakra-ui/react";
import { ModalBody, ModalCloseButton, Button, Input } from "@chakra-ui/react";
import SubTitle from "../../components/SubTitle";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { categorySubUpdate } from "../../redux/CategorySlice";
import { useFormik } from "formik";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function UpdateSubCategoryModal({
  isAddModal,
  onAddClose,
  data,
}) {
  const dispatch = useDispatch();
  const adding = useSelector((state) => state.category.adding);

  const formik = useFormik({
    initialValues: {
      id: data.id,
      name: data.name,
    },
    onSubmit: (values) => {},
  });

  function handleSubmit() {
    dispatch(
      categorySubUpdate({
        name: document.getElementById("name").value,
        id: data.id,
        description: document.getElementById("name").value,
      })
    );
    onAddClose();
  }

  return (
    <Modal isOpen={isAddModal} onClose={onAddClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Sub-Category</ModalHeader>
        <ModalCloseButton />
        <form method="post" onSubmit={formik.handleSubmit} action="#">
          <ModalBody>
            {adding ? <Loading /> : ""}

            <SubTitle title={"Sub-category Name"} />
            <Input
              onChange={formik.handleChange}
              placeholder="Sub-category Name"
              id="name"
              type="text"
              mt={2}
              mb={2}
              value={formik.values.name}
            />
          </ModalBody>

          <ModalFooter alignSelf={"center"}>
            <Button bg={"green.500"} color={"white"} onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
