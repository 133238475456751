import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getBanner = createAsyncThunk(
  "api/banner/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/banner/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);
export const deleteBanner = createAsyncThunk(
  "api/banner/:delete",
  async (values, { dispatch }) => {
    const res = await axios.delete(`${baseUrl}/api/banner/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`, //
      },
      data: {
        id: values.id,
      },
    });

    dispatch(getBanner());
    console.log(res.data.data);
    return res.data.data;
  }
);

export const updateBanner = createAsyncThunk(
  "api/banner/",
  async (values, { dispatch }) => {
    const formData = new FormData();

    formData.append("id", values.id);
    formData.append("title", values.title);
    formData.append("sub_title", values.sub_title);
    formData.append("path", values.Path);
    formData.append("image", values.image);

    const res = await axios.put(`${baseUrl}/api/banner/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getBanner());

    return res.data.data;
  } //
);
export const addBanner = createAsyncThunk(
  "api/banner/post",
  async (values, { dispatch }) => {
    const formData = new FormData();

    formData.append("id", values.id);
    formData.append("title", values.title);
    formData.append("sub_title", values.sub_title);
    formData.append("path", values.Path);
    formData.append("image", values.image);

    const res = await axios.post(`${baseUrl}/api/banner/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(getBanner());

    return res.data.data;
  } //
);

export const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    loading: false,
    adding: false,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
  },
  extraReducers: {
    [getBanner.fulfilled]: (state, { payload }) => {
      state.banner = payload;
    },
  },
});

export const bannerReducer = bannerSlice.reducer;
// export const { loading, adding } = bannerSlice.actions;
