import { Box,Grid, GridItem } from "@chakra-ui/react";
import { IoTrashOutline,IoPencilOutline,IoAddCircleOutline } from "react-icons/io5";
import { useDisclosure } from "@chakra-ui/react";
import UpdateSubCategoryModal from "./UpdateSubCategoryModal";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { categorySubDelete } from "../../redux/CategorySlice";
import { useDispatch } from "react-redux";

export default function SubCategoryTableData({data}){
    const { isOpen:isAddModal, onOpen:onAddOpen, onClose:onAddClose } = useDisclosure();
    const dispatch = useDispatch();
    const handleDelete = () => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this subcategory?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => dispatch(categorySubDelete({id:data.id}))
              },
              {
                label: 'Cancel',
                onClick: () => console.log('Cancelled')
              }
            ]
          });
    }

    return(
        <>
            <Box>
                <Grid templateColumns='repeat(8, 1fr)' gap={1} p={2} >
                    <GridItem colSpan={6}>
                        {data.name}
                    </GridItem>
                    <GridItem colSpan={2} display={"inline-flex"} alignItems={"center"}>
                        <Box ml={2} boxShadow='md' height={"fit-content"} width={"fit-content"} p={2} borderRadius={3}  >
                                <IoPencilOutline onClick={onAddOpen} fontSize={"20px"} />
                        </Box>
                        <Box ml={2} boxShadow='md' height={"fit-content"} width={"fit-content"} p={2} borderRadius={3}  >
                                <IoTrashOutline onClick={handleDelete}  fontSize={"20px"} />
                        </Box>
                    </GridItem>
                </Grid>
                <UpdateSubCategoryModal data={data} isAddModal={isAddModal} onAddClose={onAddClose} onAddOpen={onAddOpen} />
            </Box>
        </>
    )
}