import { Grid, GridItem,Box,Image,Button,Collapse } from "@chakra-ui/react";
import { IoTrashOutline,IoChevronDown,IoChevronUp } from "react-icons/io5";
import { useDisclosure } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import moment from 'moment';
import Moment from "react-moment";

export default function OrderTableData({data}){

    return(
        <NavLink to={`/order/${data.invoice_number}/detail/${data.id}/`} key={data.id}>
            <Grid boxShadow="sm" p={2} borderRadius={'10px'} fontSize={'sm'} templateColumns='repeat(10, 1fr)' gap={1} mb={2} _hover={{bg:'#f6f6f6'}}>
                <GridItem alignSelf={"center"}>
                    #{data.invoice_number}
                </GridItem>
                <GridItem colSpan={2} alignSelf={"center"}>
                    {data.user}
                </GridItem>
                <GridItem alignSelf={"center"} colSpan={2}>
                    {data.shipping_address}
                </GridItem>
                <GridItem alignSelf={"center"}>
                    Nrs.{data.invoice_amount}
                </GridItem>
                <GridItem colSpan={2} alignSelf={"center"}>
                    {
                        <Moment date={data.date} format="LLL" />
                    }
                </GridItem>
                <GridItem alignSelf={"center"}>
                    {data.status}
                </GridItem>
                <GridItem alignSelf={"center"}>
                    Edit
                </GridItem>
            </Grid>

        </NavLink>
    );
}