import {
  Box,
  Grid,
  GridItem,
  Image,
  Collapse,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IoPersonCircleSharp } from "react-icons/io5";
import { addCustomer } from "../../redux/CustomerSlice";
import Loading from "../../components/Loading";
import SubTitle from "../../components/SubTitle";
import Moment from "react-moment";
import { addBanner, updateBanner } from "../../redux/BannerSlice";
import { ToastContainer, toast } from "react-toastify";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function AddBannerModel({ isOpen, onClose, onOpen, data }) {
  const [file, setFile] = React.useState();

  const dispatch = useDispatch();
  const adding = useSelector((state) => state.category.adding);
  console.log("Banner data", data);

  function handleUpload(event) {
    setFile(Array.from(event.target.files));
    console.log(event.target.files);
  }

  function handleSubmit() {
    if (
      document.getElementsByName("title")[0].value &&
      document.getElementsByName("sub_title")[0].value &&
      document.getElementsByName("Path")[0].value &&
      file
    ) {
      dispatch(
        addBanner({
          // id: data.id,
          title: document.getElementsByName("title")[0].value,
          sub_title: document.getElementsByName("sub_title")[0].value,
          Path: document.getElementsByName("Path")[0].value,
          image: file[0],
        })
      );
      onClose();
    } else {
      toast.error("Some fields are empty");
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ToastContainer />
      <ModalContent>
        <ModalHeader>Add Banner</ModalHeader>
        <ModalCloseButton />
        <form method="post" enctype="multipart/form-data" action="#">
          <ModalBody>
            {adding ? <Loading /> : ""}

            <Input
              accept="image/png, image/jpeg"
              id="upfile"
              display={"none"}
              type="file"
              onChange={handleUpload}
            />
            {file ? (
              <Image
                onClick={() => document.getElementById("upfile").click()}
                src={URL.createObjectURL(file[0])}
                height={"100px"}
                width={"400px"}
                objectFit="cover"
                borderRadius={"5px"}
                mb="10px"
              />
            ) : (
              <Image
                onClick={() => document.getElementById("upfile").click()}
                src={"./upload.png"}
                height={"100px"}
                width={"400px"}
                objectFit="cover"
                mb="10px"
              />
            )}
            <SubTitle title={"Title"} />
            <Input
              // defaultValue={data.title}
              placeholder="Title"
              name="title"
              type="text"
              mt={2}
              mb={2}
              required
            />

            <SubTitle title={"Info"} />
            <Input
              // defaultValue={data.sub_title}
              type="email"
              placeholder="Info"
              name="sub_title"
              mt={2}
              mb={2}
              required
            />

            <SubTitle title={"Link Path"} />
            <Input
              type="text"
              placeholder="Link path"
              name="Path"
              mt={2}
              mb={2}
              // defaultValue={data.path}
              required
            />
          </ModalBody>

          <ModalFooter alignSelf={"center"}>
            <Button
              bg={"green.500"}
              color={"white"}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
