import React, { useEffect, useRef, useState } from "react";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import { Box, HStack } from "@chakra-ui/react";
import { SaveButton } from "../../components/SaveButton";
import { CancelButton } from "../../components/CancelButton";
import ReactQuill from "react-quill";

import { getTerms, updateTerms } from "../../redux/TermsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacy, updatePrivacy } from "../../redux/PrivacySlice";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicies() {
  const editorRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const terms = useSelector((state) => state?.privacy?.privacy);
  // useEffect(() => dispatch(getTerms()));
  const [value, setValue] = useState();
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  // const handleSubmit = () => {
  //   console.log(editorRef.current.props.value);
  // };

  const handleSubmit = () => {
    dispatch(
      updatePrivacy({
        privacy_policy: editorRef.current.props.value,
      })
    );
  };
  useEffect(() => {
    dispatch(getPrivacy());
  }, []);
  useEffect(() => {
    if (terms) {
      setValue(terms);
    }
  }, [terms]);

  return (
    <SidebarAndNavWrapper
      props={
        <Box>
          <Box p={"0"} onClick={() => editorRef.current.focus()}>
            <ReactQuill
              style={{ height: "50vh", width: "90vw" }}
              ref={editorRef}
              theme="snow"
              defaultValue={value ?? ""}
              value={value ?? ""}
              onChange={setValue}
              modules={modules}
              formats={formats}
            />
          </Box>
          <Box>
            <SaveButton onClick={handleSubmit} />
            <CancelButton onClick={() => navigate(-1)} />
          </Box>
        </Box>
      }
    />
  );
}
