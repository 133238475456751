import { Box, Image, Modal, ModalOverlay } from "@chakra-ui/react";
import { ModalContent, ModalHeader, ModalFooter } from "@chakra-ui/react";
import { ModalBody, ModalCloseButton, Button, Input } from "@chakra-ui/react";
import SubTitle from "../../components/SubTitle";
import { IoImageOutline } from "react-icons/io5";
import { WithContext as ReactTags } from "react-tag-input";
import React, { useEffect } from "react";
import { categoryAdd } from "../../redux/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function AddCategoryModal({ isAddModal, onAddClose }) {
  const dispatch = useDispatch();
  const adding = useSelector((state) => state.category.adding);

  const [tags, setTags] = React.useState([]);
  const [file, setFile] = React.useState("");

  const applyStyleToTagInput = () => {
    document.querySelectorAll(".ReactTags__tag").forEach((el) => {
      el.style.setProperty("border", "1px solid lightgray", "important");
      el.style.setProperty("padding", "5px", "important");
      el.style.setProperty("border-radius", "8px", "important");
    });

    document.querySelectorAll(".ReactTags__tagInputField").forEach((el) => {
      el.style.setProperty("width", "100%", "important");
      el.style.setProperty("border", "1px solid lightgray", "important");
      el.style.setProperty("padding", "7px", "important");
      el.style.setProperty("border-radius", "8px", "important");
    });

    document.querySelectorAll(".ReactTags__remove").forEach((el) => {
      el.style.setProperty("color", "red", "important");
    });
  };

  useEffect(() => {
    applyStyleToTagInput();
  }, [tags]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  function handleUpload(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit() {
    var commaSeparatedTag = "";
    tags.map((item, i) => {
      if (i === 0) {
        commaSeparatedTag = item.text;
      } else {
        commaSeparatedTag = commaSeparatedTag + "," + item.text;
      }
    });

    if (tags.length === 0) {
      toast.error("Please enter the sub category");
    } else {
      dispatch(
        categoryAdd({
          name: document.getElementsByName("category")[0].value,
          sub_category: commaSeparatedTag,
          icon: file,
        })
      );
      onAddClose();
    }
  }

  return (
    <Modal isOpen={isAddModal} onClose={onAddClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Category</ModalHeader>
        <ModalCloseButton />
        <form method="post" enctype="multipart/form-data" action="#">
          <ModalBody>
            {adding ? <Loading /> : ""}

            <SubTitle title={"Category Icon"} />

            <Box
              boxShadow="md"
              width={"fit-content"}
              p={1}
              borderRadius={3}
              mt={2}
              mb={2}
            >
              {file ? (
                <Image height={"100px"} src={URL.createObjectURL(file)} />
              ) : (
                <IoImageOutline fontSize={"50px"} />
              )}
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleUpload}
                name="image"
              ></input>
            </Box>
            <SubTitle title={"Category Name"} />
            <Input
              placeholder="Category Name"
              name="category"
              type="text"
              mt={2}
              mb={2}
            />

            <SubTitle title={"Sub-Category Name"} />
            <ReactTags
              tags={tags}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              autocomplete
              inputFieldPosition="inline"
              handleInputFocus={() => applyStyleToTagInput()}
              name="subcategory"
            />
          </ModalBody>

          <ModalFooter alignSelf={"center"}>
            <Button bg={"green.500"} color={"white"} onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
