import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

// const baseUrl = "https://api.theyetitech.com";

export const getAbout = createAsyncThunk(
  "api/about/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/about/`, {});
    return res.data.data;
  }
);
export const updateAboutGallery = createAsyncThunk(
  "api/about-gallery/:put",
  async (values, { dispatch }) => {
    const formData = new FormData();

    formData.append("id", values.id);
    formData.append("image", values.image);

    const res = await axios.put(`${baseUrl}/api/about-gallery/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(
      getAbout({ params: "", page_number: 1, page_size: 12, status: 0 })
    );
    console.log(res.data.data);
    return res.data.data;
  } //
);

export const updateAbout = createAsyncThunk(
  "api/about/:put",
  async (values, { dispatch }) => {
    const formData = new FormData();

    formData.append("site_name", values.site_name);
    formData.append("email1", values.email1);
    formData.append("email2", values.email2);
    formData.append("contact1", values.contact1);
    formData.append("contact2", values.contact2);
    formData.append("description", values.description);
    formData.append("location1", values.location1);
    formData.append("location2", values.location2);
    formData.append("facebook_link", values.facebook_link);
    formData.append("instagram_link", values.instagram_link);
    formData.append("youtube_link", values.youtube_link);
    formData.append("logo", values.logo);
    // formData.append("about_image", values.about_image);
    formData.append("signup_image", values.signup_image);
    formData.append("login_image", values.login_image);
    formData.append("charge_list", values.charge_list);

    const res = await axios.put(`${baseUrl}/api/about/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(
      getAbout({ params: "", page_number: 1, page_size: 12, status: 0 })
    );
    console.log(res.data.data);
    return res.data.data;
  } //
);

export const aboutSlice = createSlice({
  name: "about",
  initialState: {
    loading: false,
    adding: false,
    successStatus: null,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
    resetSuccessStatus: (state, { payload }) => {
      state.successStatus = null;
    },
  },
  extraReducers: {
    [getAbout.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAbout.fulfilled]: (state, { payload }) => {
      state.getAbout = payload;
      state.loading = false;
    },
    [getAbout.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [updateAbout.fulfilled]: (state, { payload }) => {
      state.successStatus = true;
      state.updateAbout = payload;
      state.loading = false;
    },
    [updateAbout.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [updateAbout.rejected]: (state, { payload }) => {
      state.loading = false;
      state.successStatus = false;
    },
  },
});

export const aboutReducer = aboutSlice.reducer;
export const { loading, adding, resetSuccessStatus } = aboutSlice.actions;
