import React, { useEffect, useState } from "react";
import { Box, Select, Input, Button, Image, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import { IoCloudUploadOutline, IoTrashBinOutline } from "react-icons/io5";

export const SliderCard = ({
  specficImage,
  colorList,
  changeColor,
  handleUpload,
  deleteTheImage,
}) => {
  console.log("specficImage", specficImage);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    // dots: true,
    // dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".slider-nav",
    // centerPadding: "70px",
  };
  const settingsThumbs = {
    slidesToShow:
      specficImage?.length === 1
        ? 1
        : specficImage?.length === 2
        ? 2
        : specficImage?.length === 3
        ? 3
        : 4, //
    slidesToScroll: 1,
    // asNavFor: ".slider-for",
    dotsClass: "slick-dots slick-thumb",
    // dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "1.5rem",
    asNavFor: ".slider-for",
  };

  return (
    <Box
      mt="1rem"
      w="100%"
      h="33rem"
      borderWidth="1px"
      boxShadow="md"
      direction="column"
      align="center"
      borderRadius="1.5rem"
      cursor="pointer"
    >
      <Slider
        {...settingsMain}
        asNavFor={nav2}
        ref={(slider) => setSlider1(slider)}
      >
        {specficImage?.length === 0 && (
          <Box>
            <Button
              right={"65px"}
              position={"absolute"}
              // onClick={() => deleteTheImage(curr.id)}
            >
              <IoTrashBinOutline fontSize={"25px"} />
            </Button>
            <Input
              name="images"
              onChange={handleUpload}
              accept="image/png, image/jpeg"
              id="upfile"
              display={"none"}
              type="file"
              multiple
            />
            <Button
              right={"15px"}
              position={"absolute"}
              onClick={() => document.getElementById("upfile").click()}
            >
              <IoCloudUploadOutline fontSize={"25px"} />
            </Button>
            <Image
              src={"/upload2.png"}
              style={{ height: "25rem", width: "25rem", padding: "3rem" }}
              objectFit="contain"
              alt="img"
              ml="17%"
              onClick={() => document.getElementById("upfile").click()}
            />
            <Text onClick={() => document.getElementById("upfile").click()}>
              Upload Image
            </Text>
          </Box>
        )}
        {specficImage?.map((curr) => {
          return (
            <Box>
              <Select
                onChange={(e) => changeColor(e.target.value, curr.id)}
                width={"150px"}
                position={"absolute"}
                defaultValue={curr.color_id}
              >
                {colorList?.map((ite, i) => (
                  <option
                    color="white"
                    style={{ backgroundColor: ite.description }}
                    value={ite.id}
                  >
                    {ite.value}
                  </option>
                ))}
              </Select>
              <Button
                right={"65px"}
                position={"absolute"}
                onClick={() => deleteTheImage(curr.id)}
              >
                <IoTrashBinOutline fontSize={"25px"} />
              </Button>
              <Input
                name="images"
                onChange={handleUpload}
                accept="image/png, image/jpeg"
                id="upfile"
                display={"none"}
                type="file"
                multiple
              />
              <Button
                right={"15px"}
                position={"absolute"}
                onClick={() => document.getElementById("upfile").click()}
              >
                <IoCloudUploadOutline fontSize={"25px"} />
              </Button>
              <Image
                src={curr.images}
                style={{ height: "25rem", width: "25rem", padding: "3rem" }}
                objectFit="contain"
                alt="img"
                ml="17%"
              />
            </Box>
          );
        })}
      </Slider>
      <Slider
        {...settingsThumbs}
        asNavFor={nav1}
        ref={(slider) => setSlider2(slider)}
      >
        {specficImage?.map((curr) => {
          return (
            <Box>
              <Image
                src={curr.images}
                w="6rem"
                h="5rem"
                alt="img"
                objectFit="contain"
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};
