import { Image, Modal } from "@chakra-ui/react";
import { Select, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { ModalHeader, ModalFooter, ModalCloseButton } from "@chakra-ui/react";
import { Input, Button, ModalBody } from "@chakra-ui/react";
import SubTitle from "../../components/SubTitle";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { updateCustomer } from "../../redux/CustomerSlice";
import { ToastContainer, toast } from "react-toastify";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function UpdateVendorModal({ isOpen, onClose, onOpen, data }) {
  const [file, setFile] = React.useState();
  const dispatch = useDispatch();
  const adding = useSelector((state) => state.category.adding);

  function handleUpload(event) {
    setFile(Array.from(event.target.files));
    // console.log(event.target.files);
  }

  function handleSubmit() {
    // console.log("clicked");
    if (
      document.getElementsByName("name")[0].value &&
      document.getElementsByName("email")[0].value &&
      document.getElementsByName("gender")[0].value &&
      document.getElementsByName("phone")[0].value &&
      document.getElementsByName("city")[0].value &&
      document.getElementsByName("district")[0].value &&
      document.getElementsByName("country")[0].value
    ) {
      dispatch(
        updateCustomer({
          id: data.id,
          address_id: data.address_id ? data.address_id : null,
          profile: file ? file[0] : null,
          name: document.getElementsByName("name")[0].value,
          email: document.getElementsByName("email")[0].value,
          gender: document.getElementsByName("gender")[0].value,
          phone: document.getElementsByName("phone")[0].value,
          city: document.getElementsByName("city")[0].value,
          district: document.getElementsByName("district")[0].value,
          country: document.getElementsByName("country")[0].value,
        })
      );

      onClose();
    } else {
      toast.error("Some fields are empty");
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} key={data.id}>
      <ToastContainer />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {data.added_by_admin ? "Update " : ""}Customer information
        </ModalHeader>
        <ModalCloseButton />
        <form method="post" enctype="multipart/form-data" action="#">
          <ModalBody>
            {adding ? <Loading /> : ""}
            {data.added_by_admin && (
              <Input
                accept="image/png, image/jpeg"
                id="upfile"
                display={"none"}
                type="file"
                onChange={handleUpload}
              />
            )}

            {file ? (
              <Image
                onClick={() => document.getElementById("upfile").click()}
                src={URL.createObjectURL(file[0])}
                height={"100px"}
                width={"100px"}
                objectFit="cover"
                borderRadius={"50px"}
                cursor={data.added_by_admin ? "pointer" : ""}
              />
            ) : (
              <Image
                onClick={() => document.getElementById("upfile").click()}
                src={data.profile}
                height={"100px"}
                width={"100px"}
                objectFit="cover"
                borderRadius={"50px"}
                cursor={data.added_by_admin ? "pointer" : ""}
              />
            )}
            <SubTitle title={"Name"} />
            <Input
              defaultValue={data.full_name}
              placeholder="Name"
              name="name"
              type="text"
              mt={2}
              mb={2}
              required
              readonly={data.added_by_admin ? null : "readonly"}
              // readonly=""
            />

            <SubTitle title={"Gender"} />
            <Input
              defaultValue={data.gender}
              placeholder="Gender"
              name="gender"
              type="text"
              mt={2}
              mb={2}
              required
              readonly={data.added_by_admin ? null : "readonly"}
              // readonly=""
            />

            <SubTitle title={"Email"} />
            <Input
              defaultValue={data.email}
              type="email"
              placeholder="Email"
              name="email"
              mt={2}
              mb={2}
              required
              readonly={data.added_by_admin ? null : "readonly"}
            />

            <SubTitle title={"Phone"} />
            <Input
              defaultValue={data.phone}
              type="phone"
              placeholder="Phone"
              name="phone"
              mt={2}
              mb={2}
              required
              readonly={data.added_by_admin ? null : "readonly"}
            />

            <SubTitle title={"City"} />
            <Input
              type="text"
              placeholder="City Name"
              name="city"
              mt={2}
              mb={2}
              defaultValue={data.city}
              required
              readonly={data.added_by_admin ? null : "readonly"}
            />

            <SubTitle title={"District"} />
            <Input
              type="text"
              placeholder="District Name"
              name="district"
              defaultValue={data.district}
              mt={2}
              mb={2}
              required
              readonly={data.added_by_admin ? null : "readonly"}
            />
            <SubTitle title={"Country"} />
            <Input
              type="text"
              placeholder="Country Name"
              name="country"
              defaultValue={data.country}
              mt={2}
              mb={2}
              required
              readonly={data.added_by_admin ? null : "readonly"}
            />
          </ModalBody>

          <ModalFooter alignSelf={"center"}>
            {data.added_by_admin ? (
              <Button
                bg={"green.500"}
                color={"white"}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            ) : (
              <Button bg={"gray.500"} color={"white"} onClick={onClose}>
                Close
              </Button>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
