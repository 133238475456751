import {
  Grid,
  GridItem,
  Text,
  Select,
  Input,
  Image,
  Box,
} from "@chakra-ui/react";
import { InputGroup, InputLeftElement, Button, Tabs } from "@chakra-ui/react";
import { TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { HStack, useDisclosure } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getCategory } from "../../../redux/CategorySlice";
import {
  clearErrors,
  getColor,
  getProductReview,
  getSize,
  resetUpdateInventoryStatus,
} from "../../../redux/ProductSlice";
import ReactQuill from "react-quill";
import { NavLink } from "react-router-dom";
import {
  IoTrashOutline,
  IoSaveOutline,
  IoCloudUploadOutline,
} from "react-icons/io5";
import {
  productUpdate,
  addToInventory,
  updateToInventory,
} from "../../../redux/ProductSlice";
import NoData from "../../../components/NoData";
import { ToastContainer, toast } from "react-toastify";
import { deleteInventory } from "../../../redux/ProductSlice";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BsPlusLg } from "react-icons/bs";
import { AddColorModal } from "./Component/AddColorModal";
import { Review } from "./Component/Review";
import { getCustomer } from "../../../redux/CustomerSlice";

export default function ProductDetailUpdate({
  productDetail,
  setRender,
  specificTapIndex,
  setSpecificTapIndex,
}) {
  const [value, setValue] = useState(productDetail.product_info);
  const categoryList = useSelector((state) => state.category.getCategory);
  const customerList = useSelector((state) => state.customer.getCustomer);
  const dispatch = useDispatch();
  const color = useSelector((state) => state.product.getColor);

  const size = useSelector((state) => state.product.getSize);
  const inven = useSelector((state) => state.product.addInventory);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const product = useSelector((state) => state.product);
  const [file, setFile] = useState();
  console.log("pro", product);
  function handleUpload(event) {
    setFile(Array.from(event.target.files));
    console.log(event.target.files);
  }
  useEffect(() => {
    if (inven?.status === 400) {
      toast.error(inven.data.message);
      confirmAlert({
        title: inven.data.message,
        buttons: [
          {
            label: "Ok",
            onClick: () => dispatch(clearErrors()),
          },
        ],
      });
    }

    if (inven?.status >= 500) {
      confirmAlert({
        title: "Something went wrong! Contact your service provider.",
        buttons: [
          {
            label: "Ok",
            onClick: () => dispatch(clearErrors()),
          },
        ],
      });
      toast.error("Something went wrong! Contact your service provider.");
    }
  }, [inven]);

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getColor());
    dispatch(getSize());
    dispatch(
      getCustomer({
        page_number: 1,
        page_size: 100,
        params: "",
        status: 0,
        myUsers: true,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(getProductReview({ id: productDetail.id }));
  }, [productDetail.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      identity: productDetail.identity,
      hs_code: productDetail.hs_code,
      name: productDetail.name,
      edition: productDetail.edition,
      cost_price: productDetail.cost_price,
      selling_price: productDetail.selling_price,
    },
    onSubmit: (values) => {
      dispatch(
        productUpdate({
          id: productDetail.id,
          identity: values.identity,
          hs_code: values.hs_code,
          name: values.name,
          edition: values.edition,
          cost_price: values.cost_price,
          selling_price: values.selling_price,
          product_info: value,
          category_id: document.getElementById("category").value,
          is_active: document.getElementById("is_active").value,
          vendor: document.getElementById("vendor").value,
        })
      );
    },
  });

  const handleUpdateInventory = (id, color, size, inventory) => {
    console.log(id, color, size, inventory);
    if (inventory === "" || inventory < 0) {
      toast.error("Invalid Inventory count");
    } else {
      dispatch(
        updateToInventory({
          id: id,
          product: productDetail.id,
          size: size,
          color: color,
          product_limit: parseInt(inventory),
        })
      );
    }
  };

  useEffect(() => {
    if (product?.updateInventoryStatus === true) {
      dispatch(resetUpdateInventoryStatus());
    } else if (product?.updateInventoryStatus === false) {
      toast({
        title: "Not Success",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }
  }, [product?.updateInventoryStatus]);

  const handleDeleteInventory = (id) => {
    dispatch(
      deleteInventory({
        id: id,
        product: productDetail.id,
      })
    );
  };

  const handleAddInventory = (id, color, size, count) => {
    console.log(id, color, size, count);
    if (color === "" || size === "" || count === "") {
      toast.error("Some fields are empty");
    } else {
      dispatch(
        addToInventory({
          limit: parseInt(count),
          size: parseInt(size),
          color: parseInt(color),
          product: parseInt(id),
          image: file,
        })
      );
    }
  };

  return (
    <>
      <ToastContainer />
      {/* {product.deleteInventoryLoading ? <Loading /> : ""} */}
      <form onSubmit={formik.handleSubmit}>
        <Grid templateColumns="repeat(2, 1fr)" gap={5}>
          <GridItem colSpan={2}>
            <Tabs width={"100%"} defaultIndex={specificTapIndex}>
              <TabList>
                <Tab>Detail</Tab>
                <Tab>Inventory</Tab>
                <Tab>Reviews</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                    <GridItem colSpan={2}>
                      <Text mb={2}>Select Vendor</Text>
                      {Array.isArray(customerList?.users) ? (
                        <Select
                          id="vendor"
                          placeholder="Select option"
                          defaultValue={productDetail.vendor_id}
                        >
                          {Array.isArray(customerList?.users)
                            ? customerList?.users?.map((item) => (
                                <option value={item.id}>
                                  {item.full_name}
                                </option>
                              ))
                            : ""}
                        </Select>
                      ) : (
                        ""
                      )}
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Text mb={2}>Select Category</Text>
                      {Array.isArray(categoryList) ? (
                        <Select
                          id="category"
                          placeholder="Select option"
                          defaultValue={productDetail.sub_id}
                        >
                          {Array.isArray(categoryList)
                            ? categoryList.map((item) =>
                                Array.isArray(item.sub_category)
                                  ? item.sub_category.map((it) => (
                                      // console.log(item.name + ' - ' + it.name)
                                      <option key={it.id} value={it.id}>
                                        {item.name + " - " + it.name}
                                      </option>
                                    ))
                                  : ""
                              )
                            : ""}
                        </Select>
                      ) : (
                        ""
                      )}
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>Status</Text>
                      <Select
                        placeholder="Select"
                        id="is_active"
                        defaultValue={productDetail.status}
                      >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </Select>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>HS Code*</Text>
                      <Input
                        id="hs_code"
                        value={formik.values.hs_code}
                        onChange={formik.handleChange}
                        placeholder="HS Code"
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>Identity Code*</Text>
                      <Input
                        id="identity"
                        value={formik.values.identity}
                        onChange={formik.handleChange}
                        placeholder="Identity Code"
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>Name*</Text>
                      <Input
                        id="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Product Name"
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>Edition*</Text>
                      <Input
                        id="edition"
                        value={formik.values.edition}
                        onChange={formik.handleChange}
                        placeholder="Edition"
                      />
                    </GridItem>

                    <GridItem colSpan={2}>
                      <Text mb={2}>Description</Text>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={setValue}
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>Cost Price*</Text>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                          children="$"
                        />
                        <Input
                          id="cost_price"
                          value={formik.values.cost_price}
                          onChange={formik.handleChange}
                          placeholder="Price"
                          type="number"
                        />
                      </InputGroup>
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text mb={2}>Selling Price*</Text>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                          children="$"
                        />
                        <Input
                          id="selling_price"
                          value={formik.values.selling_price}
                          onChange={formik.handleChange}
                          placeholder="Price"
                          type="number"
                        />
                      </InputGroup>
                    </GridItem>

                    <GridItem colSpan={2}>
                      <Button type="submit" float={"right"}>
                        Update
                      </Button>
                    </GridItem>
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                    <GridItem colSpan={2}>
                      <Grid templateColumns="repeat(5, 1fr)" gap={5}>
                        {/* <GridItem colSpan={1}>
                          <input type="file" onChange={handleUpload} />
                          <Text mb={2}>Upload Image</Text>
                          <Input
                            name="images"
                            onChange={handleUpload}
                            accept="image/png, image/jpeg"
                            id="imagefile"
                            display={"none"}
                            type="file"
                            multiple
                          />

                          <Button
                            // right={"15px"}
                            // position={"absolute"}
                            onClick={() =>
                              document.getElementById("imagefile").click()
                            }
                            w="7rem"
                            mb="1rem"
                          >
                            <IoCloudUploadOutline fontSize={"25px"} />
                          </Button>
                          {file
                            ? file.map((item) => (
                                <GridItem colSpan={1}>
                                  <Image
                                    src={URL.createObjectURL(item)}
                                    objectFit={"cover"}
                                    height={"40px"}
                                    width={"100%"}
                                    borderRadius={"4px"}
                                  />
                                </GridItem>
                              ))
                            : ""}
                        </GridItem> */}
                        <GridItem colSpan={1}>
                          <HStack mb={2} cursor={"pointer"} onClick={onOpen}>
                            <Text>Color</Text>
                            <BsPlusLg />
                            <AddColorModal
                              isOpen={isOpen}
                              onClose={onClose}
                              setRender={setRender}
                            />
                          </HStack>

                          <Select placeholder="Select" id="color">
                            {Array.isArray(color)
                              ? color.map((item) => (
                                  <option value={item.id}>{item.value}</option>
                                ))
                              : ""}
                          </Select>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Text mb={2}>Size</Text>
                          <Select placeholder="Select" id="size">
                            {Array.isArray(size)
                              ? size.map((item) => (
                                  <option value={item.id}>{item.value}</option>
                                ))
                              : ""}
                          </Select>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Text mb={2}>Minimum Limit</Text>
                          <Input placeholder="count" type="number" id="count" />
                        </GridItem>
                        <GridItem colSpan={1} ml="2rem">
                          <Text mb={2}>Action</Text>
                          <Button
                            onClick={() => {
                              handleAddInventory(
                                productDetail.id,
                                document.getElementById("color").value,
                                document.getElementById("size").value,
                                document.getElementById("count").value
                              );
                              setSpecificTapIndex(1);
                            }}
                          >
                            Add
                          </Button>
                        </GridItem>
                      </Grid>
                    </GridItem>
                    <GridItem colSpan={2}>
                      {productDetail?.product_extra &&
                        productDetail.product_extra.map((index, i) => (
                          <Grid templateColumns="repeat(5, 1fr)" gap={5}>
                            {/* <GridItem colSpan={1}>
                              <Image
                                src={
                                  product?.getProductDetail?.images[i]?.images
                                }
                                objectFit={"contain"}
                                height={"40px"}
                                width={"100%"}
                                borderRadius={"2px"}
                                mb="1.5rem"
                              />
                            </GridItem> */}
                            <GridItem colSpan={1}>
                              <Text mb={2}>
                                {
                                  color?.filter(
                                    (item, i) =>
                                      color[i].id === parseInt(index.color_id)
                                  )[0]?.value
                                }
                              </Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <Text mb={2}>
                                {
                                  size?.filter(
                                    (item, i) =>
                                      size[i].id === parseInt(index.size_id)
                                  )[0]?.value
                                }
                              </Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <Input
                                placeholder="count"
                                type="number"
                                id={"count" + index.id}
                                defaultValue={index.product_limit}
                              />
                            </GridItem>
                            <GridItem
                              placeSelf={"center"}
                              colSpan={1}
                              display={"flex"}
                            >
                              <NavLink
                                to="#"
                                onClick={() =>
                                  handleUpdateInventory(
                                    index.id,
                                    index.color_id,
                                    index.size_id,
                                    document.getElementById("count" + index.id)
                                      ?.value
                                  )
                                }
                              >
                                {" "}
                                <IoSaveOutline fontSize={"30px"} />{" "}
                              </NavLink>
                              <NavLink
                                to="#"
                                onClick={() => {
                                  setSpecificTapIndex(1);
                                  handleDeleteInventory(index.id);
                                }}
                              >
                                {" "}
                                <IoTrashOutline fontSize={"30px"} />{" "}
                              </NavLink>
                            </GridItem>
                          </Grid>
                        ))}
                    </GridItem>
                  </Grid>
                </TabPanel>
                <TabPanel overflowY={"scroll"} height="31rem">
                  {product.productReview?.length === 0 ? (
                    <Box mt="30%">
                      {" "}
                      <NoData title={"No Reviews Yet"} />
                    </Box>
                  ) : (
                    <Review />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </GridItem>
        </Grid>
      </form>
    </>
  );
}
