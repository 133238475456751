import { Box, Input, Text, Button } from "@chakra-ui/react";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import * as Yup from "yup";
import { changePassword } from "../../redux/DashboardSlice";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { resetLogin } from "../../redux/LoginSlice";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";

export const PasswordChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboard = useSelector((state) => state.dashboard);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);

  const validationSchema = Yup.object({
    old_password: Yup.string().required("Please enter the old password"),
    new_password: Yup.string()
      .min(3, "Too Short!")
      .max(100, "Too Long!")
      .required("Please enter your new passsword"),
    confirm_password: Yup.string().required(
      "Please enter your confirm password"
    ),
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.new_password === values.confirm_password) {
        const arrangeValues = {
          old_password: values.old_password,
          new_password: values.new_password,
        };
        dispatch(changePassword(arrangeValues));
      } else {
        setPasswordNotMatch({ message: "Incorrect password" });
      }
    },
  });

  useEffect(() => {
    if (dashboard.changePasswordStatus) {
      toast.success("Password changed successfully!");
      dispatch(resetLogin());
    }
  }, [dashboard.changePasswordStatus]);

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {" "}
          <Box w="30%" mt="3%" ml="1%">
            {" "}
            <Box>
              <ToastContainer />
              <Text
                mb="1rem"
                fontSize={{ md: "2rem", base: "1.5rem" }}
                fontWeight="bold"
              >
                Change password
              </Text>
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <Box mr="20%" fontSize={{ md: "1rem", base: ".8rem" }}>
                    Old Password
                  </Box>
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"password"}
                    height="2.5rem"
                    mt={"4"}
                    bg="gray.300"
                    border={"none"}
                    id="old_password"
                    onChange={formik.handleChange}
                    value={formik.values.old_password}
                    placeholder="Enter Old Password"
                  />
                  {formik.errors.old_password ? (
                    <Box color="red" fontSize={"sm"} mt="2px">
                      {formik.errors.old_password}
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  <Box mr="20%" fontSize={{ md: "1rem", base: ".8rem" }}>
                    New password
                  </Box>
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"password"}
                    height="2.5rem"
                    mt={"4"}
                    bg="gray.300"
                    border={"none"}
                    id="new_password"
                    onChange={formik.handleChange}
                    value={formik.values.new_password}
                    placeholder="Enter New Password"
                  />
                  {formik.errors.new_password ? (
                    <Box color="red" fontSize={"sm"} mt="2px">
                      {formik.errors.new_password}
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  <Box mr="20%" fontSize={{ md: "1rem", base: ".8rem" }}>
                    Confirm Password
                  </Box>
                  <Input
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    type={"password"}
                    height="2.5rem"
                    mt={"4"}
                    bg="gray.300"
                    border={"none"}
                    id="confirm_password"
                    onChange={formik.handleChange}
                    value={formik.values.confirm_password}
                    placeholder="Enter Confirm Password"
                  />
                  {formik.errors.confirm_password ? (
                    <Box color="red" fontSize={"sm"} mt="2px">
                      {formik.errors.confirm_password}
                    </Box>
                  ) : null}
                  {passwordNotMatch && (
                    <Box
                      color="red.500"
                      fontSize={{ md: "1rem", base: ".8rem" }}
                    >
                      New password and confirm password doesn't match!!
                    </Box>
                  )}
                </Box>
                <Box>
                  <Button
                    fontSize={{ md: "1rem", base: ".7rem" }}
                    // color={"#F7FAFC"}
                    // colorScheme="blue"
                    // bg="gray.300"
                    w={{ md: "10rem", base: "8rem" }}
                    h="2rem"
                    type="submit"
                    mt={4}
                    border={"none"}
                    cursor={"pointer"}
                  >
                    Change Password
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </>
      }
    />
  );
};
