import { Box, Grid, GridItem } from "@chakra-ui/react";
import CustomerTableData from "./CustomerTableData";

export default function CustomerListTable({data}){
    return(
        <>

        <Box>
            {/* Table Heading */}
            <Grid fontSize={'1rem'} templateColumns='repeat(14, 1fr)' gap={1}  borderBottom={"1px solid lightgray"} p={2} mb={2} >
                <GridItem>
                    ID
                </GridItem>
                <GridItem>
                    Profile
                </GridItem>
                <GridItem colSpan={4}>
                    Customer Name
                </GridItem>
                <GridItem colSpan={2}>
                    Customer Products
                </GridItem>
                <GridItem colSpan={3}>
                    Email
                </GridItem>
                <GridItem colSpan={2}>
                    Phone
                </GridItem>
                <GridItem>
                    Action
                </GridItem>
            </Grid>
        </Box>

        {
            Array.isArray(data?.users)? data?.users.map(item=>(
                <CustomerTableData data={item} />
            ))
            :''
        }
        
        </>
    );
}