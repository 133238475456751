import {
  Grid,
  GridItem,
  Input,
  InputLeftElement,
  InputGroup,
  Button,
  Box,
  Heading,
  Center,
} from "@chakra-ui/react";
import {
  IoSearchOutline,
  IoArrowForwardCircleOutline,
  IoArrowBackCircleOutline,
} from "react-icons/io5";
import AddProductDrawer from "./AddProductDrawer";
import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProductListTable from "./ProductListTable";
import PageTitle from "../../components/PageTitle";
import SidebarAndNavWrapper from "../../components/WrapperComponent/SidebarAndNavWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getProduct, resetAddProduct } from "../../redux/ProductSlice";
import { NavLink } from "react-router-dom";
import Loading from "../../components/Loading";
import { refreshToken } from "../../redux/LoginSlice";

export default function ProductList() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const productList = useSelector((state) => state.product.getProduct);
  const termList = useSelector((state) => state.product.getProduct);
  console.log("term", termList);

  const loading = useSelector((state) => state.product.loading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [params, setParams] = useState("");
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    dispatch(
      getProduct({
        page_number: pageNumber,
        page_size: pageSize,
        params: params,
      })
    );
    setReRender(false);
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh_token") }));
  }, [pageNumber, params, reRender]);
  const [value, setValue] = useState("");
  useEffect(() => {
    dispatch(resetAddProduct());
    setValue("");
  }, [isOpen]);

  return (
    <SidebarAndNavWrapper
      props={
        <>
          {loading ? <Loading /> : ""}
          <Box>
            <PageTitle title={"Products"} />
            <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={5}>
              <GridItem>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                    children={<IoSearchOutline />}
                  />
                  <Input
                    onChange={(e) => setParams(e.target.value)}
                    placeholder="Search Products by name,identity code"
                    value={params}
                    borderRadius={"30px"}
                  />
                </InputGroup>
              </GridItem>
              <GridItem></GridItem>
              <GridItem>
                <Button
                  ref={btnRef}
                  onClick={onOpen}
                  float={"right"}
                  mr={"50px"}
                >
                  Add Product
                </Button>
              </GridItem>
            </Grid>
            {productList ? (
              <ProductListTable setReRender={setReRender} data={productList} />
            ) : (
              "No Data"
            )}
            <Center>
              <Box textAlign={"-webkit-center"} display={"inline-flex"} mt={2}>
                {pageNumber > 1 && (
                  <NavLink to="#" onClick={() => setPageNumber(pageNumber - 1)}>
                    <IoArrowBackCircleOutline fontSize={"25px"} />
                  </NavLink>
                )}
                {productList?.has_next && (
                  <NavLink to="#" onClick={() => setPageNumber(pageNumber + 1)}>
                    <IoArrowForwardCircleOutline fontSize={"25px"} />
                  </NavLink>
                )}
              </Box>
            </Center>
            <AddProductDrawer
              isOpen={isOpen}
              onClose={onClose}
              btnRef={btnRef}
              setValue={setValue}
              value={value}
            />
          </Box>
          {/* <Box onClick={onClose}>nice</Box> */}
        </>
      }
    />
  );
}
