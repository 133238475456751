import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../pages/constant";

export const getOrder = createAsyncThunk(
  "api/admin/order-list/",
  async (values, thunkAPI) => {
    const search = values.params === "" ? "None" : values.params;

    const res = await axios.get(
      `${baseUrl}/api/admin/order-list/?invoice_id=` +
        search +
        `&page_number=` +
        values.page_number +
        `&page_size=` +
        values.page_size +
        `&status=` +
        values.status+
        `&cid=${values.cid?values.cid:'all'}`+
        `&myUsers=${values.myUsers}`
        ,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);

export const getOrderStatus = createAsyncThunk(
  "api/status/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/status/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    console.log(res.data.data);
    return res.data.data;
  }
);

export const getOrderInvoice = createAsyncThunk(
  "api/admin/invoice/<int:invoice_number>/details/",
  async (values, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/admin/invoice/${values.id}/details/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);
export const updateInvoice = createAsyncThunk(
  "/admin/invoice/<int:invoice_number>/update/",
  async (values, { dispatch }) => {
    const res = await axios.put(
      `${baseUrl}/api/admin/invoice/${values.invoice_number}/update/`,
      {
        shipping_charge: values.shipping_charge,
        discount: values.discount,
        payment_complete: values.payment_complete,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    dispatch(getOrderInvoice({ id: values.id }));
    console.log(res.data.data);
    return res;
  }
);

export const changeOrderStatus = createAsyncThunk(
  "api/admin/order/<uuid>/change-status/",
  async (values, thunkAPI) => {
    const res = await axios.put(
      `${baseUrl}/api/admin/order/${values.uuid}/change-status/`,
      {
        status_id: values.status_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  }
);

export const addOrder = createAsyncThunk(
  "api/admin/order-product/",
  async (values, { dispatch }) => {
    const res = await axios.post(
      `${baseUrl}/api/admin/order-product/`,
      {
        products: values.products,
        size: values.size,
        color: values.color,
        quantity: values.quantity,
        user: values.user,
        shipping_time: values.shipping_time,
        shipping_address: values.shipping_address,
        payment_complete: values.payment_complete,
        comment: values.comment,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    console.log(res.data.data);
    dispatch(
      getOrder({
        page_number: 1,
        page_size: 12,
        params: "None",
        status: "None",
        cid:"all",
        myUsers:'all'
      })
    );
    return res.data.data;
  } //
);

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    adding: false,
  },

  reducers: {
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    adding: (state, { payload }) => {
      state.adding = payload;
    },
    clearUpdateInvoiceStatus: (state) => {
      state.updateInvoiceStatus = {};
    },
  },
  extraReducers: {
    [addOrder.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [addOrder.fulfilled]: (state, { payload }) => {
      state.addOrder = payload;
      state.loading = false;
    },
    [addOrder.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [getOrder.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateInvoice.fulfilled]: (state, { payload }) => {
      state.updateInvoiceStatus = payload;
    },
    [updateInvoice.rejected]: (state, { payload }) => {
      state.updateInvoiceStatus = payload;
    },
    [getOrder.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getOrder.fulfilled]: (state, { payload }) => {
      state.getOrder = payload;
      state.loading = false;
    },

    [getOrderInvoice.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getOrderInvoice.fulfilled]: (state, { payload }) => {
      state.getOrderInvoice = payload;
      state.loading = false;
    },
    [getOrderInvoice.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getOrderStatus.fulfilled]: (state, { payload }) => {
      state.getOrderStatus = payload;
      state.loading = false;
    },
    [changeOrderStatus.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [changeOrderStatus.fulfilled]: (state, { payload }) => {
      state.changeOrderStatus = payload;
      state.loading = false;
    },
    [changeOrderStatus.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const orderReducer = orderSlice.reducer;
export const { loading, adding, clearUpdateInvoiceStatus } = orderSlice.actions;
