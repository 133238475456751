import { Box, Grid, GridItem, HStack, Text } from "@chakra-ui/react";

import { IoPencilOutline, IoEyeSharp } from "react-icons/io5";
import { useState } from "react";
import BannerTableData from "./BannerTableData";

export default function BannerListTable({ banner, setReRender }) {
  const [hidingCostPrice, setHidingCostPrice] = useState(true);
  console.log("banner", banner);
  return (
    <>
      <Box>
        {/* Table Heading */}
        <Grid
          templateColumns="1fr 2fr 2fr 2fr  2fr"
          gap={1}
          borderBottom={"1px solid lightgray"}
          p={2}
          mb={2}
        >
          <GridItem>Image</GridItem>
          <GridItem>Title</GridItem>
          <GridItem>Info</GridItem>
          <GridItem>Created Date</GridItem>
          <GridItem>Action</GridItem>
        </Grid>
      </Box>

      {Array.isArray(banner)
        ? banner?.map((item) => (
            <BannerTableData
              data={item}
              setReRender={setReRender}
              hidingCostPrice={hidingCostPrice}
            />
          ))
        : ""}
    </>
  );
}
