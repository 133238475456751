import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { CloseIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function SearchItems({
  data,
  addOrderItem,
  hideSearch,
  setHideSearch,
}) {
  //   const [comment, setComment] = useState(null);
  const order = (id, name, category, price, sizecolor, freq, comment) => {
    if (freq >= 1) {
      addOrderItem(id, name, category, price, sizecolor, freq, comment);
      document.getElementById("freq" + id).value = 0;
      document.getElementById("comment" + id).value = "";
    } else {
      toast.error("Frequency must be entered");
    }
  };

  function closeIconOnClickHandler() {
    setHideSearch(true);
  }

  return (
    <>
      <ToastContainer />

      <Grid
        fontSize={"12px"}
        display={hideSearch ? "none" : "grid"}
        zIndex={100}
        textAlign={"center"}
        width={"100%"}
        templateColumns="repeat(8, 1fr)"
        gap={1}
        bg={"#f6f6f6"}
        border={"1px solid lightgray"}
        borderRadius={"0 0 20px 20px"}
        position={"absolute"}
        mt={"40px"}
        padding="1rem"
      >
        <InputRightElement
          width="4.5rem"
          mr="-1.4rem"
          mt="-0.3rem"
          cursor={"pointer"}
          onClick={closeIconOnClickHandler}
        >
          {" "}
          <CloseIcon w="3" h="3" />
        </InputRightElement>

        {Array.isArray(data)
          ? data.map((item, i) => (
              <>
                <GridItem p={2} w="4rem">
                  <Image
                    src={item.icon} //
                    h="3rem"
                    w="4rem"
                    objectFit={"cover"}
                  />
                </GridItem>
                <GridItem p={3} textAlign={"left"} fontSize="sm" w="9rem">
                  {item.name}
                </GridItem>
                <GridItem p={3} textAlign={"left"} fontSize="sm">
                  {item.category}
                </GridItem>
                <GridItem p={3} fontSize="sm">
                  <select id={"sizecolor" + item.id}>
                    {item.extra.map((ite, it) => (
                      <option
                        value={`{ "size":"${ite.size}", "color": "${ite.color}", "color_id": "${ite.color_id}", "size_id": "${ite.size_id}" }`}
                        key={it}
                      >
                        {ite.color + "-" + ite.size}
                      </option>
                    ))}
                  </select>
                </GridItem>
                <GridItem p={3} fontSize="sm">
                  Nrs.{item.price}{" "}
                </GridItem>
                <GridItem p={3} w="8rem">
                  <Box>
                    <Input
                      height={"25px"}
                      type="number"
                      id={"freq" + item.id}
                      defaultValue="0"
                    />
                  </Box>
                </GridItem>
                {/* <GridItem p={3} fontSize="sm">
                  <Text> comment</Text>
                </GridItem> */}
                <GridItem p={3} fontSize="sm" w="10rem">
                  <Input
                    type="text"
                    height={"25px"}
                    placeholder="add comment"
                    id={"comment" + item.id}
                    // value={comment}
                    // onChange={(e) => setComment(e.target.value)}
                  />
                </GridItem>
                <GridItem p={3} fontSize="sm">
                  <Box bg={"#f9f9f9"}>
                    <NavLink
                      to="#"
                      onClick={() =>
                        order(
                          item.id,
                          item.name,
                          item.category,
                          item.price,
                          document.getElementById("sizecolor" + item.id).value,
                          document.getElementById("freq" + item.id).value,
                          document.getElementById("comment" + item.id).value
                        )
                      }
                    >
                      <u>Add</u>
                    </NavLink>
                  </Box>
                </GridItem>
              </>
            ))
          : ""}
      </Grid>
    </>
  );
}
