import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  Input,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  FormLabel,
} from "@chakra-ui/react";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { IoSearchOutline, IoTrashOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getCustomer } from "../../redux/CustomerSlice";
import SearchItems from "../../components/SearchItems";
import { searchProduct } from "../../redux/ProductSlice";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { addOrder } from "../../redux/OrderSlice";

export default function AddOrderModal({
  isOpen,
  onClose,
  setSelectedCustomer,
  selectedCustomer,
}) {
  // const customerList = [{value:1,label:'alish'}]
  const dispatch = useDispatch();
  const customerList = useSelector((state) =>
    Array.isArray(state.customer.getCustomer?.users)
      ? state.customer.getCustomer?.users.map(
          ({ full_name: label, id: value, ...rest }) => ({
            label,
            value,
            ...rest,
          })
        )
      : []
  );

  let productList = useSelector((state) => state.product.searchProduct);

  const [page, setPage] = useState(1);
  const [orderItem, setOrderItem] = useState([]);
  const [hideSearch, setHideSearch] = useState(true);
  const [inventory, setInventory] = useState([{ id: 0, value: "" }]); //

  useEffect(() => {
    dispatch(
      getCustomer({
        page_number: 1,
        page_size: 50,
        params: "",
        status: 1,
        myUsers:true
      })
    );
  }, []);

  const handleChange = (options) => {
    setSelectedCustomer(options);
  };

  const addOrderItem = (
    id,
    name,
    category,
    price,
    sizecolor,
    freq,
    comment
  ) => {
    console.log("orderItem", [
      ...orderItem,
      {
        id: id,
        name: name,
        category: category,
        price: price,
        sizecolor: JSON.parse(sizecolor),
        freq: freq,
        comment: comment,
      },
    ]);
    setOrderItem([
      ...orderItem,
      {
        id: id,
        name: name,
        category: category,
        price: price,
        sizecolor: JSON.parse(sizecolor),
        freq: freq,
        comment: comment,
      },
    ]);
  };

  const removeFromOrderItem = (id) => {
    setOrderItem(orderItem.filter((item, i) => item.id !== id));
  };

  const addToSearchList = (text) => {
    if (text) {
      setHideSearch(false);
      dispatch(searchProduct({ text: text }));
    } else {
      setHideSearch(true);
    }
  };

  const validate = () => {
    console.log(selectedCustomer);
    if (page === 1 && selectedCustomer.length === 0) {
      toast.error("Please select customer!");
    } else if (page === 1 && selectedCustomer) {
      setPage(page + 1);
    }

    if (page === 2 && orderItem.length === 0) {
      toast.error("No Product Selected Yet!");
    } else if (page === 2 && orderItem.length > 0) {
      // console.log(orderItem);
      // console.log(orderItem.map(({ id }) => id));
      // console.log(orderItem.map(({ freq }) => parseInt(freq)));
      // console.log(orderItem.map(({ sizecolor }) => parseInt(sizecolor.color_id)));
      // console.log(orderItem.map(({ sizecolor }) => parseInt(sizecolor.size_id)));
      setPage(page + 1);
    }

    if (
      page === 3 &&
      document.getElementById("shipping_address").value !== "" &&
      document.getElementById("shipping_time").value !== "" &&
      document.querySelector('input[name="payment"]:checked')
    ) {
      dispatch(
        addOrder({
          products: orderItem.map(({ id }) => id),
          size: orderItem.map(({ sizecolor }) => parseInt(sizecolor.size_id)), //
          color: orderItem.map(({ sizecolor }) => parseInt(sizecolor.color_id)),
          quantity: orderItem.map(({ freq }) => parseInt(freq)),
          comment: orderItem.map(({ comment }) => comment),
          user: selectedCustomer.value,
          shipping_address: document.getElementById("shipping_address").value,
          shipping_time: new Date(
            document.getElementById("shipping_time").value + ":00"
          ),
          payment_complete:
            document.querySelector('input[name="payment"]:checked').value ===
            "1"
              ? true
              : false,
        })
      );
      onClose();
    } else if (
      page === 3 &&
      (document.getElementById("shipping_address").value === "" ||
        document.getElementById("shipping_time").value === "" ||
        !document.querySelector('input[name="payment"]:checked'))
    ) {
      toast.error("Please add shipping details!");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
      <ToastContainer />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>
          {page <= 3 && "Add Order"}
          {page === 4 && "Your Order has been placed!"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading fontSize={"sm"} mb={2}>
            {page === 1 && "Select Customer"}
            {page === 2 && "Order Items"}
            {page === 3 && "Shipping Details"}
          </Heading>

          {page === 1 && (
            <Select
              isClearable={true}
              isSearchable={true}
              name="color"
              value={selectedCustomer}
              onChange={handleChange}
              options={customerList}
            />
          )}

          {page === 2 && (
            <>
              <Grid
                fontSize={"12px"}
                textAlign={"left"}
                templateColumns="repeat(12, 1fr)"
                gap={1}
                borderBottom={"1px solid lightgray"}
              >
                {/* Search */}
                <GridItem colSpan={12} mb={4}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                      children={<IoSearchOutline />}
                    />
                    <Input
                      placeholder="Search Products"
                      borderRadius={"30px"}
                      onChange={(e) => addToSearchList(e.target.value)}
                    />
                    <SearchItems
                      hideSearch={hideSearch}
                      data={productList}
                      addOrderItem={addOrderItem}
                      setHideSearch={setHideSearch}
                    />
                  </InputGroup>
                </GridItem>
                {/* table */}
                <GridItem>SN</GridItem>
                <GridItem colSpan={2}>Prouct</GridItem>
                <GridItem colSpan={2}>Category</GridItem>
                <GridItem colSpan={2}>Color-Size</GridItem>
                <GridItem colSpan={2}>Price</GridItem>
                <GridItem>Freq.</GridItem>
                <GridItem>Total</GridItem>
                <GridItem>Remove</GridItem>
              </Grid>

              {Array.isArray(orderItem)
                ? orderItem.map((item, i) => (
                    <Grid
                      fontSize={"12px"}
                      p={2}
                      templateColumns="repeat(12, 1fr)"
                      gap={1}
                      borderBottom={"1px solid lightgray"}
                    >
                      <GridItem>{i + 1}</GridItem>
                      <GridItem colSpan={2}>{item.name}</GridItem>
                      <GridItem colSpan={2}>{item.category}</GridItem>
                      <GridItem colSpan={2}>
                        {/* <select>
                                            {
                                                item.extra.map((ite,it) => 
                                                    (
                                                        <option value={ite.id} key={it}>{ite.color+ "-" + ite.size}</option>
                                                    )
                                                )
                                            }
                                        </select> */}
                        {item.sizecolor.color + "-" + item.sizecolor.size}
                      </GridItem>
                      <GridItem colSpan={2}>Nrs.{item.price}</GridItem>
                      <GridItem colSpan={1}>x{item.freq}</GridItem>
                      <GridItem>
                        Nrs.{parseFloat(item.price) * parseInt(item.freq)}
                      </GridItem>
                      <GridItem placeSelf={"center"}>
                        <NavLink
                          to="#"
                          onClick={() => removeFromOrderItem(item.id)}
                        >
                          <IoTrashOutline fontSize={20} textAlign={"center"} />
                        </NavLink>
                      </GridItem>
                    </Grid>
                  ))
                : "No Product Selected"}
            </>
          )}

          {page === 3 && (
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              <GridItem colSpan={1}>
                <FormLabel>Shipping Address</FormLabel>
              </GridItem>
              <GridItem colSpan={2}>
                <Input type={"text"} id="shipping_address" />
              </GridItem>

              <GridItem colSpan={1}>
                <FormLabel>Date To Ship</FormLabel>
              </GridItem>
              <GridItem colSpan={2}>
                <Input type={"datetime-local"} id="shipping_time" />
              </GridItem>

              <GridItem colSpan={1}>
                <FormLabel>Payment Received?</FormLabel>
              </GridItem>
              <GridItem colSpan={2}>
                <label for="payment-yes" style={{ marginRight: "10px" }}>
                  Yes
                </label>
                <input
                  type="radio"
                  id="payment-yes"
                  name="payment"
                  value="1"
                  style={{ marginRight: "20px" }}
                />

                <label for="payment-no" style={{ marginRight: "10px" }}>
                  No
                </label>
                <input id="payment-no" type="radio" name="payment" value="0" />
              </GridItem>
            </Grid>
          )}

          {page === 4 && (
            <Heading fontSize={"md"} textAlign={"center"}>
              Your Order has been placed. <br></br> Trackuig number is #2312
            </Heading>
          )}
        </ModalBody>

        <ModalFooter>
          {page !== 1 && page < 4 && (
            <Button
              variantColor="gray"
              mr={3}
              onClick={() => setPage(page - 1)}
            >
              Back
            </Button>
          )}

          {page < 3 && (
            <Button variantColor="blue" mr={3} onClick={() => validate()}>
              Next
            </Button>
          )}

          {page === 3 && (
            <Button
              type="submit"
              variantColor="blue"
              mr={3}
              onClick={() => validate()}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
